import React, { useState } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { removeMemberType } from "../../../../../../../models/request/mentoring-request";
import { useRemoveMemberMutation } from "../../../../../../../services/mentoring-service";

const LeaveGroup = ({groupId}) => {
  const navigate = useNavigate();

  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const userId = parseInt(storedUserId);

  const [removeData, setRemoveData] = useState<removeMemberType>({
    userId: userId,
    groupId: groupId,
  });

  const [removeMember] = useRemoveMemberMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await removeMember(removeData);
      const { code, message, data } = response["data"];
      if (code === 0) {
        toast.success(message);
        navigate("/e-mentoring/leave-group");
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };
  return (
    <div>
      {" "}
      <Button
      onClick={handleFormSubmit}
        style={{ fontSize: "14px" }}
        variant="danger"
        className="rounded-5"
      >
        Leave Group
      </Button>
    </div>
  );
};

export default LeaveGroup;
