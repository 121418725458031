
import React from "react";
import {
  Col,
  Container,
  Row,
  Image,
  Form,
  FloatingLabel,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { useGetUserByIdQuery } from "../../../../../../services/user-service";
import PageLoader from "../../../../../components/PageLoader";

const CounsellorProfile = () => {
  const { userId } = useParams();
  const ID = Number(userId);

  const { data: response, isLoading } = useGetUserByIdQuery(ID);
  const profile = response?.data;

  if (isLoading) {
    return <PageLoader />;
  }

  const springboardCategoryOptions = Array.isArray(profile?.springboardCategory)
  ? profile.springboardCategory
  : [];

  const disabilityStatusOptions = Array.isArray(profile?.disabilityStatus)
  ? profile.disabilityStatus
  : [];

  return (
    <Container className="bg-white">
      <Row>
        <Col className=" p-3" md={6}>
          <h5 className="m-0 p-0 settings-title">Profile Photo</h5>
          <p className="m-0 p-0 settings-text text-muted">Description</p>
        </Col>
        <Col style={{ backgroundColor: "#FAFAFA" }} md={6}>
          <div className="mx-auto p-3 d-grid">
            <Image
              src="https://placehold.co/160x160"
              alt=""
              className="img-fluid rounded-circle mx-auto "
            />
          </div>
        </Col>
      </Row>
      <hr className="hr w-100" />
      <Row>
        <Col className=" p-3" md={6}>
          <h5 className="m-0 p-0 settings-title">General Information</h5>
          <p className="m-0 p-0 settings-text text-muted">Description</p>
        </Col>
        <Col className=" m-0 p-0" md={6}>
          <FloatingLabel
            controlId="floatingInput"
            label="First Name"
            className="my-2 settings-text"
          >
            <Form.Control
              type="text"
              placeholder="First Name"
              name="firstName"
              value={profile?.firstName}
              disabled
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInput"
            label="Middle Name"
            className="my-2 settings-text"
          >
            <Form.Control
              type="text"
              placeholder="Middle Name"
              value={profile?.middleName}
              name="middleName"
              disabled
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInput"
            label="Last Name"
            className="my-2 settings-text"
          >
            <Form.Control
              type="text"
              placeholder="Last Name"
              name="lastName"
              value={profile?.lastName}
              disabled
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInput"
            label="E-mail"
            className="my-2 settings-text"
          >
            <Form.Control
              type="email"
              placeholder="E-mail"
              name="email"
              value={profile?.email}
              disabled
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInput"
            label="Phone"
            className="my-2 settings-text"
          >
            <Form.Control
              type="text"
              placeholder="Phone"
              name="phone"
              value={profile?.phone}
              disabled
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInput"
            label="Whatsapp Number"
            className="my-2 settings-text"
          >
            <Form.Control
              type="text"
              placeholder="Whatsapp Number"
              name="whatsappNumber"
              value={profile?.whatsappNumber}
              disabled
            />
          </FloatingLabel>
          <FloatingLabel
            className="mb-2 settings-text"
            controlId="floatingSelect"
            label="Sex"
          >
            <Form.Select
              aria-label="Floating label select example"
              value={profile?.gender}
              name="gender"
              disabled
            >
              <option selected disabled>
                Sex
              </option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </Form.Select>
          </FloatingLabel>
          <FloatingLabel
            className="mb-2 settings-text"
            controlId="floatingSelect"
            label="Age"
          >
            <Form.Select
              aria-label="Floating label select example"
              value={profile?.age}
              name="age"
              disabled
            >
              <option selected disabled>
                Age
              </option>
              <option value="AGE_BELOW_15">Below 15yrs</option>
              <option value="AGE_15_17">15 – 17 yrs</option>
              <option value="AGE_18_24">18 – 24 yrs</option>
              <option value="AGE_25_30">25 – 30 yrs </option>
              <option value="AGE_31_35">31 – 35 years </option>
              <option value="AGE_36_PLUS">36+ years </option>
            </Form.Select>
          </FloatingLabel>
          <FloatingLabel
            className="mb-2 settings-text"
            controlId="floatingSelect"
            label="highestLevelOfEducation"
          >
            <Form.Select
              aria-label="Floating label select example"
              value={profile?.highestLevelOfEducation}
              name="highestLevelOfEducation"
              disabled
            >
              <option value="" disabled>
                Highest Level of Eduction
              </option>
              <option value="No Formal Education">No Formal Education</option>
              <option value="Primary">Primary</option>
              <option value="Junior High School (JHS)">
                Junior High School (JHS)
              </option>
              <option value="Senior High School (SHS)">
                Senior High School (SHS)
              </option>
              <option value="TVET (Secondary)">TVET (Secondary)</option>
              <option value="TVET (Tertiary)">TVET (Tertiary)</option>
              <option value="First Degree / Bachelors">
                First Degree / Bachelors
              </option>
              <option value="Master’s Degree">Master’s Degree</option>
              <option value="Doctorate Degree">Doctorate Degree</option>
            </Form.Select>
          </FloatingLabel>
          <Select
            className="basic-multi-select mb-2"
            classNamePrefix="select"
            isMulti
            name="springboardCategory"
            placeholder="Select Springboard Category"
            value={springboardCategoryOptions.map((category) => ({
              value: category,
              label: category,
            }))}
            isDisabled
          />
             <Select
            className="basic-multi-select mb-2"
            classNamePrefix="select"
            isMulti
            name="disabilityStatus"
            placeholder="Select disability status"
            value={disabilityStatusOptions.map((category) => ({
              value: category,
              label: category,
            }))}
            isDisabled
          />
        </Col>
      </Row>
    </Container>
  );
};

export default CounsellorProfile;
