import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { OnchangeProps } from "../../../../../../models/request/create-event.request";
import { useGetDistrictByRegionIdQuery, useGetEventRegionListQuery } from "../../../../../../services/event-service";

interface RegionType {
  id: number;
  name: string;
}

interface DistrictType {
  id: number;
  regionId: number;
  name: string;
}

interface RegionDistrictProps extends OnchangeProps {
  districtValue: number;
  regionValue: number;
}

const RegionDistrict: React.FC<RegionDistrictProps> = ({
  name,
  onChange,
  districtValue,
  regionValue,
}) => {
  const [selectedRegionId, setSelectedRegionId] = useState<number | null>();
  console.log("districtValue", districtValue);

  const { data: regionResponse, isLoading: regionLoading } =
    useGetEventRegionListQuery({ page: 0, size: 99999999 });

  const regions: RegionType[] = regionResponse?.data[0]?.data || [];

  const RegionOptions: { value: number; label: string }[] = regions.map(
    (region) => ({ value: region.id, label: region.name })
  );

  const {
    data: districtResponse,
    isLoading: districtLoading,
    refetch,
  } = useGetDistrictByRegionIdQuery(selectedRegionId, {
    skip: !selectedRegionId,
  });

  const districts: DistrictType[] = (districtResponse?.data ||
    []) as DistrictType[];

  const DistrictOptions: { value: number; label: string }[] = districts.map(
    (dist) => ({ value: dist.id, label: dist.name })
  );

  console.log("districts", districts);

  const handleRegionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const regionId = parseInt(event.target.value);
    setSelectedRegionId(regionId);
  };

  useEffect(() => {
    if (regionValue) {
      setSelectedRegionId(regionValue);
    }
  }, [regionValue]);

  useEffect(() => {
    if (selectedRegionId) {
      refetch();
    }
  }, [selectedRegionId, refetch]);

  const renderLoadingMessage = (loadingMessage: string) => (
    <div>{loadingMessage}</div>
  );

  return (
    <Row className="m-0 p-0">
      <Col className="m-0 " lg={6}>
        <Form.Group className="mb-2" controlId="regionSelect">
          <div className="d-flex align-items-center">
            <Form.Label className="event-label">Region</Form.Label>
          </div>
          <Form.Select
            className="event-details-form"
            aria-label="Select region"
            onChange={handleRegionChange}
            value={selectedRegionId || regionValue}
          >
            <option className="text-muted" value="" disabled>
              Select region
            </option>

            {regionLoading
              ? renderLoadingMessage("Loading regions...")
              : RegionOptions.map((region) => (
                  <option key={region.value} value={region.value}>
                    {region.label}
                  </option>
                ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col className="m-0 " lg={6}>
        <Form.Group className="mb-2" controlId="districtSelect">
          <div className="d-flex align-items-center">
            <Form.Label className="event-label">District</Form.Label>
          </div>
          <Form.Select
            className="event-details-form"
            aria-label="Select district"
            name={name}
            onChange={onChange}
            value={districtValue}
          >
            <option className="text-muted" value="" disabled>
              Select district
            </option>
            {districtLoading
              ? renderLoadingMessage("Loading districts...")
              : DistrictOptions.map((district) => (
                  <option key={district.value} value={district.value}>
                    {district.label}
                  </option>
                ))}
          </Form.Select>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default RegionDistrict;
