import React from "react";
import { Button, Container, Row, Col, Card } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { RiArrowLeftLine } from "react-icons/ri";

const ApproveRequestMessage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const request = location.state?.request;

  return (
    <div>
      <div className="bg-enquiries">
        <Container className="vh-100 d-flex align-items-center justify-content-center">
          <Row className="justify-content-center">
            <Col md={12} xs={12}>
              <div className="py-13 pt-lg-20 pb-lg-20 text-center">
                <h1 className="display-3 fw-medium text-white mb-5">
                  Approved!
                </h1>
                <div
                  className="modal show rounded-1"
                  style={{ display: "block", position: "initial" }}
                >
                  <Col md={6} className="mx-auto">
                    <Card className="text-center">
                      <Card.Body>
                        <Card.Text className="my-3">
                          You have approved {request?.requestedBy[0]?.firstName}{" "}
                          {request?.requestedBy[0]?.lastName} to join{" "}
                          {request?.groupName}
                        </Card.Text>

                        <Row>
                          <Col className="text-center my-3">
                            <Button
                              variant="dark"
                              type="submit"
                              className="rounded-5"
                              onClick={() => navigate(-1)}
                            >
                              Go back
                              <RiArrowLeftLine />
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ApproveRequestMessage;
