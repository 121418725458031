import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { userService } from "../services/user-service";
import { formService } from "../services/form-service";
import { formAnswerService } from "../services/form-answers-service";
import { formDropDownService } from "../services/form-dropdown-suggestion";
import { fileUploadService } from "../services/file-upload-service";
import { eventService } from "../services/event-service";
import { authService } from "../services/auth-service";
import { enquiryService } from "../services/enquiry-service";
import { counsellingService } from "../services/counselling-service";
import { mentoringService } from "../services/mentoring-service";

export const store = configureStore({
  reducer: {
    [userService.reducerPath]: userService.reducer,
    [formService.reducerPath]: formService.reducer,
    [formAnswerService.reducerPath]: formAnswerService.reducer,
    [formDropDownService.reducerPath]: formDropDownService.reducer,
    [fileUploadService.reducerPath]: fileUploadService.reducer,
    [eventService.reducerPath]: eventService.reducer,
    [authService.reducerPath]: authService.reducer,
    [enquiryService.reducerPath]: enquiryService.reducer,
    [counsellingService.reducerPath]: counsellingService.reducer,
    [mentoringService.reducerPath]: mentoringService.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      userService.middleware,
      formService.middleware,
      formAnswerService.middleware,
      formDropDownService.middleware,
      fileUploadService.middleware,
      eventService.middleware,
      authService.middleware,
      enquiryService.middleware,
      counsellingService.middleware,
      mentoringService.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
