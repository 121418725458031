import CreateGroupHeader from "./components/CreateGroupHeader";
import CreateGroupBody from "./components/CreateGroupBody";
import ProtectedRoutes from "../../../auth/utils/ProtectedRoutes";

const CreateGroup = () => {
  return (
    <div className="">
      <CreateGroupHeader />
      <CreateGroupBody />
    </div>
  );
};

export default ProtectedRoutes(CreateGroup, {
  allowedRoles: ["Super Admin", "Admin", "Call Center Manager"],
});
