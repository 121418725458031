
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { approveRequestType } from "../../../../../../../models/request/mentoring-request";
import { useDeclineRequestMutation } from "../../../../../../../services/mentoring-service";

const DeclineRequest = ({ request }) => {
  const navigate = useNavigate();
  const [declineData, setDeclineData] = useState<approveRequestType>({
    requestIds: [request.id],
  });

  const [decline] = useDeclineRequestMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await decline(declineData);
      const { code, message, data } = response["data"];
      if (code === 0) {
        toast.success(message);
        navigate("/e-mentoring/decline", { state: { request } });
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };
  return (
    <div>
      <Button
        onClick={handleFormSubmit}
        style={{ fontSize: "12px" }}
        variant="danger"
        className="rounded-5 mx-1"
      >
        Decline
      </Button>
    </div>
  );
};

export default DeclineRequest;
