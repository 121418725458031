import {Form } from "react-bootstrap";
import { useGetEventPartnersListQuery } from "../../../../../../services/event-service";
import { OnchangeProps } from "../../../../../../models/request/create-event.request";

interface HostType {
  id: number;
  name: string;
}

const Host: React.FC<OnchangeProps> = ({ name, onChange }) => {
  const { data: response, isLoading } = useGetEventPartnersListQuery({
    page: 0,
    size: 99999999,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const users: HostType[] = (response?.data[0]?.data ?? []) as HostType[];

  const HostOptions: { value: number; label: string }[] = users.map(
    (hosts) => ({
      value: hosts.id,
      label: hosts.name,
    })
  );

  return (
    <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
      <Form.Select
        className="event-details-form"
        aria-label="Default select example"
        name={name}
        onChange={onChange}
      >
        <option className="text-muted" value="" selected disabled>
          Select host
        </option>
        {HostOptions.map((host) => (
          <option key={host.value} value={host.value}>
            {host.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};

export default Host;
