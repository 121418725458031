import EditGroupHeader from "./components/EditGroupHeader";
import EditGroupBody from "./components/EditGroupBody";
import ProtectedRoutes from "../../../auth/utils/ProtectedRoutes";

const EditGroup = () => {
  return (
    <div className="">
      <EditGroupHeader />
      <EditGroupBody />
    </div>
  );
};

export default ProtectedRoutes(EditGroup, {
  allowedRoles: ["Super Admin", "Admin", "Call Center Manager"],
});
