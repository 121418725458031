import React, { useState } from "react";
import { Button, Card, Modal, Row, Image } from "react-bootstrap";
import { PiSuitcaseSimple } from "react-icons/pi";
import {  TbUsers } from "react-icons/tb";
import { VscBook } from "react-icons/vsc";

const ViewAssignedRequest = ({ assignedData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let icon = null;
  switch (assignedData?.requestType) {
    case "RELATIONSHIP":
      icon = <TbUsers />;
      break;
    case "EDUCATION":
      icon = <VscBook />;
      break;
    case "CAREER":
      icon = <PiSuitcaseSimple />;
      break;
    default:
      icon = null;
      break;
  }
  return (
    <>
      <Button
        variant="light"
        className="rounded-5 "
        style={{ fontSize: "12px", background: "rgba(25, 22, 29, 0.06)" }}
        onClick={handleShow}
      >
        View details ...
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Card>
          <Card.Body className="p-5">
            <div className="text-center">
              <h4 className="mb-0 fw-semibold">
                {" "}
                <span className="fs-2">{icon}</span> {assignedData?.requestType}
              </h4>
              <p className="mb-0">Ref Code: {assignedData?.referenceCode}</p>
            </div>
            <Row style={{ fontSize: "12px" }} className="">
              <div className="d-flex justify-content-between border-bottom py-2 mt-4">
                <span>Requested by</span>
                <span className="text-dark">
                  <Image
                    src={`https://ui-avatars.com/api/?name=${assignedData?.requestedBy[0]?.firstName}+${assignedData?.requestedBy[0].lastName}&background=random&size=32`}
                    alt="Avatar"
                    roundedCircle
                    className="me-2"
                  />
                  {assignedData?.requestedBy[0]?.firstName}{" "}
                  {assignedData?.requestedBy[0]?.lastName}
                </span>
              </div>
              <div className="d-flex  justify-content-between border-bottom py-2">
                <span>Preferred Start Date</span>
                <span className="">
                  {assignedData?.preferredStartAt
                    ? new Date(
                        assignedData?.preferredStartAt
                      ).toLocaleDateString()
                    : ""}{" "}
                  -{" "}
                  {assignedData?.preferredStartAt
                    ? new Date(
                        assignedData?.preferredStartAt
                      ).toLocaleTimeString()
                    : ""}
                </span>
              </div>
              <div className="d-flex justify-content-between border-bottom py-2">
                <span>Preferred End Date</span>

                <span className="">
                  {assignedData?.preferredEndAt
                    ? new Date(
                        assignedData?.preferredEndAt
                      ).toLocaleDateString()
                    : ""}{" "}
                  -{" "}
                  {assignedData?.preferredEndAt
                    ? new Date(
                        assignedData?.preferredEndAt
                      ).toLocaleTimeString()
                    : ""}
                </span>
              </div>
              <div className="d-flex justify-content-between border-bottom py-2">
                <span>Counsellors</span>
                <span className="text-dark">
                  {" "}
                  {assignedData?.confirmation?.counsellors.map(
                    (counsellor: any) => (
                      <div key={counsellor.id}>
                        <Image
                          src={`https://ui-avatars.com/api/?name=${counsellor.firstName}+${counsellor.lastName}&background=random&size=32`}
                          alt="Avatar"
                          roundedCircle
                          className="me-2"
                        />
                        {counsellor.firstName} {counsellor.lastName}
                      </div>
                    )
                  )}
                </span>
              </div>
              <div className="d-flex  justify-content-between border-bottom py-2">
                <span>Start Date</span>
                <span className="">
                  {assignedData?.confirmation?.startAt
                    ? new Date(
                        assignedData?.confirmation?.startAt
                      ).toLocaleDateString()
                    : ""}{" "}
                  -{" "}
                  {assignedData?.confirmation?.startAt
                    ? new Date(
                        assignedData?.confirmation?.startAt
                      ).toLocaleTimeString()
                    : ""}
                </span>
              </div>
              <div className="d-flex justify-content-between border-bottom py-2">
                <span> End Date</span>

                <span className="">
                  {assignedData?.confirmation?.endAt
                    ? new Date(
                        assignedData?.confirmation?.endAt
                      ).toLocaleDateString()
                    : ""}{" "}
                  -{" "}
                  {assignedData?.confirmation?.endAt
                    ? new Date(
                        assignedData?.confirmation?.endAt
                      ).toLocaleTimeString()
                    : ""}
                </span>
              </div>
              <div className="d-flex justify-content-between border-bottom py-2">
                <span> Date Created</span>

                <span className="">
                  {assignedData?.createdOn
                    ? new Date(assignedData?.createdOn).toLocaleDateString()
                    : ""}{" "}
                </span>
              </div>
              <div className="d-flex justify-content-between pt-2">
                <span>Participant Feedback</span>
                {assignedData?.confirmation?.participantFeedback &&
                assignedData.confirmation.participantFeedback.length > 0 ? (
                  <span className="">
                    {assignedData.confirmation.participantFeedback.map(
                      (feedback: any) => (
                        <div key={feedback.id}>
                          <p className="mb-0">{feedback.comments}</p>
                          <p>Ratings: {feedback.rating}</p>
                        </div>
                      )
                    )}
                  </span>
                ) : (
                  <span>No feedback available</span>
                )}
              </div>
            </Row>
          </Card.Body>
        </Card>
      </Modal>
    </>
  );
};

export default ViewAssignedRequest;
