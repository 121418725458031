import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { IoMdMore } from "react-icons/io";
import DeleteEvent from "../../deleteEvent/DeleteEvent";
import { Link } from "react-router-dom";

const EventDropdown = ({ eventId }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const roles = sessionStorage.getItem("roles");
  const allowedRoles = ["Admin", "Super Admin", "Call Center Manager"];
  const hasAllowedRole =
    roles && allowedRoles.some((role) => roles.includes(role));

  return (
    <div style={{ position: "relative" }}>
      <div onClick={toggleDropdown} style={{ cursor: "pointer" }}>
        <IoMdMore />
      </div>
      <Dropdown
        show={showDropdown}
        onToggle={toggleDropdown}
        drop="start"
        title="Drop start"
        id="dropdown-button-drop-start"
        style={{ position: "absolute", top: "100%", right: "150px" }}
      >
        <Dropdown.Menu style={{ fontSize: "14px" }}>
          {hasAllowedRole ? (
            <>
              <Dropdown.Item as={Link} to={`/events/edit-event/${eventId}`}>
                Edit Event
              </Dropdown.Item>
              <DeleteEvent deleteId={eventId} />
            </>
          ) : (
            <Dropdown.Item as={Link} to={`/events/event-details/${eventId}`}>
              View Details
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default EventDropdown;
