import EventsHeader from "./components/EventHeader";
import OngoingEventCard from "./ongoingEvents/OngoingEventCard";
import UpcomingEventCard from "./upcomingEvents/UpcomingEventCard";
import EndedEventCard from "./endedEvents/EndedEventCard";
import RegisteredEventCard from "./registerEvents/RegisteredEventCard";
import { useState } from "react";
import UserOngoingEvents from "./ongoingEvents/UserOngoingEvents";
import UserAttendedEvents from "./endedEvents/UserAttendedEvents";
import ProtectedRoutes from "../../../auth/utils/ProtectedRoutes";

const Events = () => {
  const roles = sessionStorage.getItem("roles");
  const [searchQuery, setSearchQuery] = useState("");
  const isAdmin =
    roles.includes("Super Admin") ||
    roles.includes("Admin") ||
    roles.includes("Call Center Manager");
  return (
    <div>
      <EventsHeader searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      <OngoingEventCard searchQuery={searchQuery} />
      <UpcomingEventCard searchQuery={searchQuery} />
      {isAdmin ? (
        <>
          <EndedEventCard searchQuery={searchQuery} />
        </>
      ) : (
        <>
          <RegisteredEventCard />
          <UserOngoingEvents searchQuery={searchQuery} />
          <UserAttendedEvents searchQuery={searchQuery} />
        </>
      )}
    </div>
  );
};

export default ProtectedRoutes(Events, {
  allowedRoles: [
    "Super Admin",
    "Admin",
    "User",
    "Counsellor",
    "Mentor",
    "Facilitator",
    "Call Center Manager",
    "Coordinator",
    "Usher"
  ],
});
