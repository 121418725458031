import React from "react";
import { Button } from "react-bootstrap";
import { FaFileCsv } from "react-icons/fa6";
import { BulkUploadFile } from "../../../../../assets";

const SampleBulkUploadFile = () => {
  const handleDownload = () => {

    const fileUrl = BulkUploadFile;
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", "BulkUpload.xlsx");
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  return (
    <div>
      <Button
        variant="light"
        className="rounded-5"
        style={{ fontSize: "12px", background: "rgba(25, 22, 29, 0.06)" }}
        onClick={handleDownload}
      >
        Bulk Upload File <FaFileCsv />
      </Button>
    </div>
  );
};

export default SampleBulkUploadFile;
