import EditEventHeader from "./components/EditEventHeader";
import EditEventDetails from "./components/EditEventDetails";
import ProtectedRoutes from "../../../auth/utils/ProtectedRoutes";

const EditEvent = () => {
  return (
    <div>
      <EditEventHeader />
      <EditEventDetails />
    </div>
  );
};

export default ProtectedRoutes(EditEvent, {
  allowedRoles: ["Super Admin", "Admin", "Call Center Manager"],
});
