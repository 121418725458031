import React, { ChangeEvent, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { partnerType } from "../../../../../../models/request/create-event.request";
import { useCreateEventPartnerMutation } from "../../../../../../services/event-service";

const CreatePartnerModal = () => {
  const [show, setShow] = useState(false);
  const [partnerData, setPartnerData] = useState<partnerType>({
    name: "",
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setPartnerData((prevRegionData) => ({
      ...prevRegionData,
      [name]: value,
    }));
  };

  // Apollo hook for mutation
  const [createPartnerMutation] = useCreateEventPartnerMutation();

  // Function to handle form submission
  const handleCreatePartner = async (e) => {
    e.preventDefault();
    try {
      // Make the mutation call
      const response = await createPartnerMutation(partnerData);
      const { code, message } = response["data"];
      if (code === "00") {
        toast.success(message);
      } else {
        toast.error(message || "Something went wrong");
      }
      // Close the modal
      handleClose();
    } catch (error) {
      toast.error(error.message);
      console.error("Error creating region:", error);
    }
  };

  return (
    <>
      <Button
        className="py-2 rounded-5 mx-auto"
        variant="dark"
        style={{ fontSize: "14px" }}
        onClick={handleShow}
      >
        Create Partner +
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Create a partner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: "14px" }}>Partner</Form.Label>
              <Form.Control size="lg"
                className="p-2"
                type="text"
                placeholder="Enter partner name"
                autoFocus
                name="name"
                onChange={handleFormChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="rounded-5"
            variant="secondary"
            onClick={handleClose}
            style={{ fontSize: "14px" }}
          >
            Close
          </Button>
          <Button
            style={{ fontSize: "14px" }}
            className="rounded-5"
            variant="primary"
            onClick={handleCreatePartner} // Call handleCreateRegion on button click
          >
            Create partner
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreatePartnerModal;
