import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BaseResponse } from "../models/response/base-response";
import { DropdownSuggestionFilterRequest } from "../models/request/dropdown-suggestion-filter-request";
import { DropdownSuggestionResponse } from "../models/response/dropdown-suggestion-filter-response";
import queryString from 'query-string';
import { AppConstants } from "../core/mocks/app-constants";

export const formDropDownService = createApi({
  reducerPath: "formDropDownService",
  baseQuery: fetchBaseQuery({
    baseUrl: `${AppConstants.formBaseUrl}`,
  }),

  tagTypes: ["FormDropdown"],
  endpoints: (build) => ({
    filterFormDropdowns: build.mutation<BaseResponse<DropdownSuggestionResponse[]>, DropdownSuggestionFilterRequest>({
      query: (request: DropdownSuggestionFilterRequest) => ({
        url: `drop-down-suggestion/filter?${queryString.stringify(request, { skipNull: true })}`,
        method: "GET",
      }),
      // providesTags: ["FormDropdown"],
    }),
  }),
});

export const {
  useFilterFormDropdownsMutation
} = formDropDownService;
