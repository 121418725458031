import React, { ChangeEvent, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { MdDeleteForever } from "react-icons/md";
import { useAddUserEmployeeMutation } from "../../../../services/user-service";

const AddEmployees = () => {
  const [storedRegUserId] = useState(sessionStorage.getItem("registrationId"));
  const regUserId = parseInt(storedRegUserId);

  const [employeeData, setEmployeeData] = useState({
    employerId: regUserId,
    employees: [],
  });

  const [showForm, setShowForm] = useState(false); // State for controlling form visibility

  const [addUserEmployee] = useAddUserEmployeeMutation();

  const handleFormChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    const { name, value } = e.target;

    const updatedEmployees = [...employeeData.employees];
    updatedEmployees[index] = {
      ...updatedEmployees[index],
      [name]: value,
    };

    setEmployeeData((prevData) => ({
      ...prevData,
      employees: updatedEmployees,
    }));
  };

  const handleCreateEmployee = async (index: number) => {
    try {
      const response = await addUserEmployee({
        employerId: employeeData.employerId,
        employees: [employeeData.employees[index]],
      });
      const { code, message } = response["data"];
      if (code === 200) {
        toast.success(message);
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error creating employee:", error);
    }
  };

  const handleAddEmployee = () => {
    setEmployeeData((prevData) => ({
      ...prevData,
      employees: [
        ...prevData.employees,
        {
          firstName: "",
          lastName: "",
          category: "",
          phone: "",
        },
      ],
    }));
  };

  const handleDeleteEmployee = (index: number) => {
    const updatedEmployees = [...employeeData.employees];
    updatedEmployees.splice(index, 1);
    setEmployeeData((prevData) => ({
      ...prevData,
      employees: updatedEmployees,
    }));
  };

  return (
    <div>
      {!showForm && (
        <Row className="mt-5">
          <Col>
            <Button
              className="py-2 rounded-5 mx-auto"
              variant="light"
              style={{
                fontSize: "12px",
                background: "rgba(25, 22, 29, 0.06)",
              }}
              onClick={() => {
                setShowForm(true);
                if (employeeData.employees.length === 0) {
                  // Add an initial employee if the employees array is empty
                  handleAddEmployee();
                }
              }}
            >
              Add Employee
            </Button>
          </Col>
        </Row>
      )}

      {showForm &&
        employeeData.employees.map((employee, index) => (
          <div key={index}>
            <Row className="my-1">
              <Col className="my-1" md={6}>
                <Form.Group>
                  <Form.Control
                    name="firstName"
                    placeholder="First name"
                    value={employee.firstName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleFormChange(e, index)
                    }
                    required
                    style={{ fontSize: "14px" }}
                  />
                </Form.Group>
              </Col>
              <Col className="my-1" md={6}>
                <Form.Group>
                  <Form.Control
                    name="lastName"
                    placeholder="Last name"
                    value={employee.lastName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleFormChange(e, index)
                    }
                    required
                    style={{ fontSize: "14px" }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="my-1">
              <Col className="my-1" md={6}>
                <Form.Group>
                  <Form.Select
                    name="category"
                    value={employee.category}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                      handleFormChange(e, index)
                    }
                    required
                    style={{ fontSize: "14px" }}
                  >
                    <option value="" disabled>
                      Select category
                    </option>
                    <option value="Intern">Internship</option>
                    <option value="Apprentice">Apprentice</option>
                    <option value="Employee">Employee</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col className="my-1" md={6}>
                <Form.Group>
                  <Form.Control
                    name="phone"
                    placeholder="Mobile Number"
                    value={employee.phone}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleFormChange(e, index)
                    }
                    required
                    style={{ fontSize: "14px" }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="ml-auto">
              <Col className="my-auto d-flex my-2">
                <Button
                  variant="success rounded-5 ms-auto"
                  onClick={() => handleCreateEmployee(index)}
                  style={{ fontSize: "13px" }}
                >
                  Save Employee
                </Button>
                <MdDeleteForever
                  className="text-danger my-auto fs-4"
                  onClick={() => handleDeleteEmployee(index)}
                />
              </Col>
            </Row>
          </div>
        ))}

      {showForm && (
        <Row className="mt-5">
          <Col>
            <Button
              className="py-2 rounded-5 mx-auto"
              variant="light"
              style={{
                fontSize: "12px",
                background: "rgba(25, 22, 29, 0.06)",
              }}
              onClick={handleAddEmployee}
            >
              Add Another Employee
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default AddEmployees;
