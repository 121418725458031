import { FC, useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { DropDownSuggestionDTO, FormQuestion } from "../../models/response/form-response";
import { useFilterFormDropdownsMutation } from "../../services/form-dropdown-suggestion";

type GetFormByTypeProps = {
  question: FormQuestion;
  onSelectQuestion: (
    suggestion: string,
    question: FormQuestion,
    isDropDown: Boolean | false
  ) => void;
  bindValue?: string | null;
  disabled: any;
};

export const GetFormByType: FC<GetFormByTypeProps> = ({
  question,
  onSelectQuestion,
  bindValue,
  disabled,
}) => {
  const [suggestions, setSuggestions] = useState<DropDownSuggestionDTO[]>([]);
  const [filterFormDropdown] = useFilterFormDropdownsMutation();
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (question.type === "DROP_DOWN_QUESTION") {
      fetchDropdownSuggestions();
    }
  }, [bindValue]);

  const fetchDropdownSuggestions = () => {
    const shouldFetchSuggestions =
      (question.parentQuestionId && bindValue) || !question.parentQuestionId;
    if (shouldFetchSuggestions) {
      filterFormDropdown({
        bind_value: bindValue,
        question_id: question.id,
      })
        .unwrap()
        .then((resp) => {
          console.log("resp >> ", resp);
          setSuggestions(resp.data);
        });
    }
  };

  const handleInputChange = (event) => {
    const answer = event.target.value;
    onSelectQuestion(answer, question, false);
    setInputValue(answer);
  };

  switch (question.type) {
    case "TEXT_QUESTION":
      return (
        <Form.Group as={Col} md={6}>
          <Form.Control
            name={"answers"}
            value={inputValue}
            onChange={handleInputChange}
            placeholder={question.title}
            disabled={disabled}
            required
          />
        </Form.Group>
      );

    case "DATE_QUESTION":
      return <div>Data form here</div>;

    case "DROP_DOWN_QUESTION":
      return (
        <Form.Group as={Col} md={6}>
          <Form.Select
            onChange={(event) =>
              onSelectQuestion(event.target.value, question, true)
            }
            disabled={disabled}
            required
            className="mr-2 text-muted fs-form-input"
          >
            <option className="text-muted mx-0 fs-form-input disabled">
              {question.title}
            </option>
            {suggestions?.map((suggestion, key) => (
              <option
                key={key}
                value={JSON.stringify(suggestion)}
                className="text-muted mx-0 fs-form-input disabled"
              >
                {suggestion.value}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      );

    case "INTEGER_QUESTION":
      return <div>Number form here</div>;

    case "DOUBLE_QUESTION":
      return <div>Number form here</div>;

    default:
      return <div> doest match any component</div>;
  }
};
