import FullDetailsEventHeader from "./components/FullDetailsEventHeader";
import FullDetailsEventBody from "./components/FullDetailsEventBody";
import { useParams } from "react-router-dom";
import PageLoader from "../../../../components/PageLoader";
import { useGetEventByIdQuery } from "../../../../../services/event-service";

const FullDetailsEvent = () => {
  const { id } = useParams();
  const eventid = Number(id);

  const { data: response, isLoading } = useGetEventByIdQuery(eventid);
  const eventData = response?.data?.[0];

  const eventStatus = eventData?.status || "N/A";
  const eventExpectations = eventData?.expectations;
  const eventReviews = eventData?.reviews;
  const eventCollaborators =
    eventData?.collaborators?.map((collaborator) => collaborator.name) || [];
  const eventMediaHouses =
    eventData?.mediaHouses?.map((mediaHouse) => mediaHouse.name) || [];
  const eventParticipant = (eventData?.participants || []).map(
    (participant) => ({
      name: participant.name,
      userId: participant.userId,
    })
  );
  const eventCheckIn = (eventData?.checkIns || []).map((checkIn) => ({
    firstName: checkIn.participantFirstName,
    lastName: checkIn.participantLastName,
    phone: checkIn.participantPhoneNumber,
  }));

  const eventFacilitators = Array.isArray(eventData?.facilitators)
    ? eventData.facilitators
    : [];

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div>
      <FullDetailsEventHeader
        eventData={eventData}
        eventId={eventid}
        eventstatus={eventStatus}
        eventParticipantsWithUserId={eventParticipant.map(
          (participant) => participant.userId
        )}
      />
      <FullDetailsEventBody
        eventData={eventData}
        eventId={eventid}
        eventcollaborators={eventCollaborators}
        eventmediahouses={eventMediaHouses}
        eventparticipants={eventParticipant}
        eventcheckin={eventCheckIn}
        eventstatus={eventStatus}
        eventfacilitators={eventFacilitators}
        eventexpectations={eventExpectations}
        eventreviews={eventReviews}
      />
    </div>
  );
};

export default FullDetailsEvent;
