import { ChangeEvent, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { createGroupType } from "../../../../../../models/request/mentoring-request";
import { useCreateGroupMutation } from "../../../../../../services/mentoring-service";

const CreateGroupBody = () => {
  const navigate = useNavigate();

  const [group, setGroup] = useState<createGroupType>({
    name: "",
    description: "",
  });

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setGroup((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [createGroup] = useCreateGroupMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await createGroup(group);
      const { code, message } = response["data"];
      if (code === 0) {
        navigate("/e-mentoring");
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };

  return (
    <Container>
      <Row className="p-4 align-items-center">
        <h5 className="">Group Details</h5>
        <hr className="hr" />
      </Row>
      <Row className="px-4">
        <Col>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Group Name</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                placeholder="Enter Group Name"
                name="name"
                onChange={handleFormChanged}
                value={group.name}
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Group Description</Form.Label>
              <Form.Control
                onChange={handleFormChanged}
                placeholder="Enter Group Description"
                name="description"
                as="textarea"
                value={group.description}
                rows={3}
              />
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button
                className="rounded-5"
                variant="dark"
                onClick={handleFormSubmit}
                disabled={!group.name || !group.description}
              >
                Create Group
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateGroupBody;
