import Select, { MultiValue } from "react-select";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { useGetUsersListQuery } from "../../../../../../services/user-service";
import {
  useCreateBulkParticipantsMutation,
  useDeleteEventParticipantsMutation,
} from "../../../../../../services/event-service";

const AdminParticipantEvent = ({ EventId, eventparticipants }) => {
  const { data: users, isLoading } = useGetUsersListQuery({
    page_index: 0,
    page_size: 99999999,
  });

  console.log("eventparticipants", eventparticipants);
  const participants = users?.data || [];

  const roles = sessionStorage.getItem("roles");
  const allowedRoles = ["Admin", "Super Admin", "Call Center Manager", "Usher"];
  const hasAllowedRole =
    roles && allowedRoles.some((role) => roles.includes(role));

  const participantsOptions = participants.map((user) => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName}`,
    phone: user.phone,
  }));

  const [selectedParticipants, setSelectedParticipants] = useState<any[]>([]);

  const [createBulkParticipants] = useCreateBulkParticipantsMutation();

  const handleSelectChange = (newValue: MultiValue<any>) => {
    setSelectedParticipants([...newValue]);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedParticipants.length === 0) {
        toast.error("Please select at least one participant.");
        return;
      }
      const participantsPayload = selectedParticipants.map((participant) => ({
        name: participant.label,
        userId: participant.value,
        phoneNumber: participant.phone,
      }));
      const response = await createBulkParticipants({
        eventId: EventId,
        participants: participantsPayload,
      });
      const { code, message } = response["data"];
      if (code === "00") {
        toast.success("Participants have been added to this event");
        setSelectedParticipants([]);
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error:", error);
    }
  };

  const [deleteEventParticipants] = useDeleteEventParticipantsMutation();

  const handleUnregister = async (userId) => {
    try {
      const response = await deleteEventParticipants({
        eventId: EventId,
        participantId: userId,
      });
      const { code, message } = response["data"];
      console.log("Response:", response);
      if (code === "00") {
        const removedParticipant = eventparticipants.find(
          (participant) => participant.userId === userId
        );
        toast.success(
          `${removedParticipant.name} has been removed from this event`
        );
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };

  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const userId = parseInt(storedUserId);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      border: "none",
      borderRadius: "2rem",
    }),
  };
  return (
    <Row className="">
      <Card.Text className="full-details-event-description m-0">
        <div className="d-flex align-items-center">
          <h5 className="py-1 w-25 m-0 event-label">Participants</h5>
          <hr className="hr w-75" />
        </div>
        <Badge
          pill
          bg="light"
          style={{ fontSize: "13px" }}
          className="text-dark ms-auto fw-normal"
        >
          Total : {eventparticipants.length}
        </Badge>
        {hasAllowedRole && (
          <>
            {eventparticipants.length > 0
              ? eventparticipants.map((participant) => (
                  <Badge
                    pill
                    bg={userId === participant.userId ? "dark" : "light"}
                    text={userId === participant.userId ? "white" : "dark"}
                    className="me-1 p-2 fw-normal"
                    key={participant.userId}
                    style={{ fontSize: "12px" }}
                  >
                    {participant.name}

                    <GrFormClose
                      onClick={() => handleUnregister(participant.userId)}
                      className="ms-1 fs-6 text-dark"
                    />
                  </Badge>
                ))
              : null}
          </>
        )}
      </Card.Text>
      {hasAllowedRole && (
        <div>
          <Col className="mt-3">
            <Select
              className="basic-multi-select"
              classNamePrefix="select"
              options={participantsOptions}
              isDisabled={isLoading}
              placeholder="Add Participants to Events"
              isLoading={isLoading}
              styles={customStyles}
              isMulti
              value={selectedParticipants}
              onChange={handleSelectChange}
            />
          </Col>
          <Row className="mt-2">
            <Col className="d-grid">
              <Button
                onClick={handleFormSubmit}
                variant="dark"
                style={{ fontSize: "14px", borderRadius: "2rem" }}
              >
                Add +
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </Row>
  );
};

export default AdminParticipantEvent;
