import { Container, Navbar, Form, Col, Row } from "react-bootstrap";
import { useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import ProtectedRoutes from "../../../auth/utils/ProtectedRoutes";
import AddCounsellorAvailability from "./components/AddCounsellorAvailability";
import { CounsellorRequest } from "./components/CounsellorRequest";

const CounsellorCalender = () => {
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <Container fluid>
      <Navbar expand="lg" className="py-3 p-4 bg-transparent">
        <Navbar.Brand className="fs-4">Mentor</Navbar.Brand>

        <Navbar.Toggle aria-controls="myNavbar2" className="navbar-toggler">
          <RxHamburgerMenu className="text-black fs-4" />
        </Navbar.Toggle>

        <Navbar.Collapse id="myNavbar2">
          <Row className="ms-auto flex-wrap align-items-center">
            <Col className="mx-auto m-0 p-0 position-relative">
              <span className="position-absolute ps-2 pt-2 py-auto">
                <IoSearchOutline />
              </span>
              <Form.Control
                type="search"
                className="py-2 border border-1 rounded-5"
                style={{ paddingLeft: "1.8rem" }}
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Col>
            <Col>
              <AddCounsellorAvailability />
            </Col>
          </Row>
        </Navbar.Collapse>
      </Navbar>
      <Row>
        <Col>
          <CounsellorRequest />
        </Col>
      </Row>
    </Container>
  );
};

export default ProtectedRoutes(CounsellorCalender, {
  allowedRoles: ["Counsellor"],
});
