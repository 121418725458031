import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppUser } from "../models/response/app-user";
import { AppConstants } from "../core/mocks/app-constants";
import { BaseResponse } from "../models/response/base-response";
import {
  CreateUser,
  CoordinatorCreateUser,
  editUserType,
  otpPhone,
  otpUser,
} from "../models/request/create-user.request";
import { employeeType, ussdUser } from "../models/request/login-user.request";

export const userService = createApi({
  reducerPath: "userService",
  baseQuery: fetchBaseQuery({
    baseUrl: `${AppConstants.userBaseUrl}`,
  }),

  tagTypes: ["Users"],
  endpoints: (build) => ({
    getUsers: build.mutation<BaseResponse<AppUser[]>, void>({
      query: () => ({
        url: `/users`,
        method: "GET",
      }),
      invalidatesTags: ["Users"],
    }),
   
    createUser: build.mutation<BaseResponse<AppUser>, CreateUser>({
      query: (createUserRequest) => ({
        url: "/user",
        method: "POST",
        body: createUserRequest,
      }),
      invalidatesTags: ["Users"],
    }),

    updateUserByID: build.mutation<BaseResponse<AppUser>, editUserType>({
      query: (updateUserRequest: editUserType) => ({
        url: "/user",
        method: "PUT",
        body: updateUserRequest,
      }),
      invalidatesTags: ["Users"],
    }),
    deleteUser: build.mutation<void, number>({
      query: (id) => ({
        url: `/users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Users"],
    }),
    getUsersList: build.query<
      BaseResponse<AppUser>,
      { page_index: number; page_size: number }
    >({
      query: ({ page_index, page_size }) => ({
        url: `/user/list?page_index=${page_index}&page_size=${page_size}`,
        method: "GET",
      }),
      providesTags: ["Users"],
    }),
    getUsersListFilter: build.query<
      BaseResponse<AppUser>,
      { page_index: number; size: number; filterParams?: string }
    >({
      query: ({ page_index, size, filterParams }) => ({
        url: `/user/filter?page_index=${page_index}&size=${size}&sort=DESC${
          filterParams ? `&${filterParams}` : ""
        }`,
        method: "GET",
      }),
      providesTags: ["Users"],
    }),

    getUserById: build.query<BaseResponse<AppUser>, number>({
      query: (userId) => ({
        url: `/user/${userId}`,
        method: "GET",
      }),
      providesTags: ["Users"],
    }),
    deleteUserById: build.mutation<BaseResponse<AppUser>, number>({
      query: (userId) => ({
        url: `/user/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Users"],
    }),
    bulkUpload: build.mutation<BaseResponse<AppUser>, FormData>({
      query: (formData: FormData) => ({
        url: "/user/migration",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Users"],
    }),
    checkUserValidation: build.query<
      BaseResponse<AppUser>,
      { email: string; phone: string }
    >({
      query: ({ email, phone }) => ({
        url: `/user/exists?email=${email}&phone=${phone}`,
        method: "GET",
      }),
      providesTags: ["Users"],
    }),
    generateOTP: build.mutation<BaseResponse<AppUser>, otpPhone>({
      query: (otpRequest) => ({
        url: "/otp/generate",
        method: "POST",
        body: otpRequest,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Users"],
    }),
    verifyOTP: build.mutation<BaseResponse<AppUser>, otpUser>({
      query: (verifyRequest) => ({
        url: "/otp/verify",
        method: "POST",
        body: verifyRequest,
      }),
      invalidatesTags: ["Users"],
    }),
    ussdContinue: build.mutation<BaseResponse<AppUser>, ussdUser>({
      query: (ussdData) => ({
        url: "/user/ussd/continue",
        method: "POST",
        body: ussdData,
      }),
      invalidatesTags: ["Users"],
    }),
    addUserEmployee: build.mutation<BaseResponse<AppUser>, employeeType>({
      query: (body: employeeType) => ({
        url: "/user/employee",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Users"],
    }),
    coordinatorAddUser: build.mutation<BaseResponse<AppUser>, CoordinatorCreateUser>({
      query: (body: CoordinatorCreateUser) => ({
        url: "/user/onboard",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Users"],
    }),
  }),
});

export const {
  useGetUsersMutation,
  useCreateUserMutation,
  useUpdateUserByIDMutation,
  useDeleteUserByIdMutation,
  useGetUsersListQuery,
  useGetUserByIdQuery,
  useBulkUploadMutation,
  useGetUsersListFilterQuery,
  useCheckUserValidationQuery,
  useGenerateOTPMutation,
  useVerifyOTPMutation,
  useUssdContinueMutation,
  useAddUserEmployeeMutation,
  useCoordinatorAddUserMutation,
} = userService;
