import React, { useRef, useState } from "react";
import "../assets/styles/components/_notfound.scss";
import { useUploadFileMutation } from "../../services/file-upload-service";
import { MdOutlineDescription } from "react-icons/md";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaRegCheckCircle } from "react-icons/fa";
import { BsCloudUpload } from "react-icons/bs";
import toast from "react-hot-toast";

const FileUpload = ({ getData }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<string>("select"); //select | uploading  | done
  const [uploadFileMutation] = useUploadFileMutation();
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFileData, setUploadedFileData] = useState<string | null>(null);

  // get selected data to be parsed to parent
  const getFile = (file) => {
    getData(file);
  };

  //handles if file changes
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setSelectedFiles(selectedFile);
    getFile(selectedFile);
  };

  //function to handle file change event
  const clearFileInput = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setSelectedFile(null);
    setUploadStatus("select");
  };

  const handleUploadFile = async (e) => {
    try {
      //set uploading state to updating
      setUploadStatus("uploading");
      const formData = new FormData();
      formData.append("file", selectedFiles[0]);
      const response = await uploadFileMutation(formData);
      const { code, message, data } = response["data"];

      if (code === 200) {
        toast.success(message);
        setUploadedFileData(data);
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      toast.error("Error uploading file");
      setUploadStatus("select");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const droppedFiles = Array.from(e.dataTransfer.files);
    setSelectedFiles(droppedFiles);
  };

  const handleFileInputChange = (e) => {
    const inputFiles = Array.from(e.target.files);
    setSelectedFiles(inputFiles);
    getFile(inputFiles);
    console.log("this is the file", inputFiles);
  };

  const onChooseFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const removeFile = (fileName) => {
    const updatedFiles = selectedFiles.filter((file) => file.name !== fileName);
    setSelectedFiles(updatedFiles);
  };

  return (
    <div>
      {/* file input with a reference */}
      <input
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />

      <div
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDrop={handleDrop}
        className="file-container"
      >
        <input
          ref={fileInputRef}
          type="file"
          onChange={handleFileInputChange}
          style={{ display: "none" }}
        />
        <div className="file-upload-header1">Drag and drop files here</div>
        {selectedFiles.length > 0 ? (
          <>
            <ul>
              {selectedFiles.map((file) => (
                <div key={file.name}>
                  <MdOutlineDescription className="mx-3" />
                  <span className="fs-6">{file.name} </span>
                  <button
                    onClick={() => removeFile(file.name)}
                    className="border-0 bg-transparent remove-button"
                  >
                    <IoCloseCircleOutline className="mx-3" />
                  </button>
                </div>
              ))}
            </ul>
          </>
        ) : (
          <>
            <div className="text-muted file-upload-header1">or</div>
            <button
              onClick={onChooseFile}
              className="file-upload-header1 bg-black text-white rounded-5 px-3 py-2  fw-light"
            >
              Choose File <BsCloudUpload className="text-white mx-2" />
            </button>
          </>
        )}
      </div>

      {/* Display file information and progress bar when fil is selected */}
      {selectedFile && (
        <>
          <div className="file-card">
            <span>
              {" "}
              <MdOutlineDescription className="mx-3" />
              {/* description */}
            </span>
            {/* Display filename and progress bar */}
            <div className="file-info">
              <div style={{ flex: 1 }}>
                <h6>{selectedFile?.name}</h6>
              </div>
              {/* Display clear button or upload  progress*/}
              {uploadStatus === "select" ? (
                <button onClick={clearFileInput}>
                  <span className="close-icon">
                    <IoCloseCircleOutline className="mx-3" />
                    close
                  </span>
                </button>
              ) : (
                <div className="check-circle">
                  {uploadStatus === "uploading" ? (
                    "0%"
                  ) : uploadStatus === "done" ? (
                    <span style={{ fontSize: "20px" }}>
                      <FaRegCheckCircle
                        className="mx-3"
                        style={{ fontSize: "20px" }}
                      />
                      complete
                    </span>
                  ) : null}
                </div>
              )}
            </div>
          </div>
      
        </>
      )}
    </div>
  );
};

export default FileUpload;
