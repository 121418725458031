import { ChangeEvent, useState } from "react";
import toast from "react-hot-toast";
import { Button, Form, Modal } from "react-bootstrap";
import { createTopicType } from "../../../../../../../models/request/mentoring-request";
import { useCreateTopicRequestMutation } from "../../../../../../../services/mentoring-service";

const CreateGroupTopic = ({ groupID }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState<createTopicType>({
    name: "",
    description: "",
    groupId: groupID,
    discussionTime: "",
  });

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [createTopic] = useCreateTopicRequestMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await createTopic(formData);
      const { code, message } = response["data"];
      if (code === 0) {
        setFormData({
          name: "",
          description: "",
          groupId: groupID,
          discussionTime: "",
        });
        toast.success(message);
        handleClose();
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };

  return (
    <>
      <Button
        style={{ fontSize: "14px" }}
        variant="dark"
        className="rounded-5"
        onClick={handleShow}
      >
        Create Topic +
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">Create Topic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Name</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                placeholder="Enter Name"
                name="name"
                onChange={handleFormChanged}
                value={formData.name}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Discussion Time</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                placeholder="Eg. Every Monday from 5 - 6pm"
                name="discussionTime"
                onChange={handleFormChanged}
                value={formData.discussionTime}
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label> Description</Form.Label>
              <Form.Control
                onChange={handleFormChanged}
                name="description"
                as="textarea"
                value={formData.description}
                rows={3}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button
            className="rounded-5"
            variant="primary"
            onClick={handleFormSubmit}
            disabled={!formData.name || !formData.description}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateGroupTopic;
