import React, { useState } from "react";
import { bulkCheckinType } from "../../../../../../models/request/create-event.request";
import { useBulkEventCheckInMutation } from "../../../../../../services/event-service";
import toast from "react-hot-toast";
import Select, { MultiValue } from "react-select";
import { Button, Col, Form, Row } from "react-bootstrap";

const BulkCheckIn = ({ eventData }) => {
  const participants = eventData?.participants?.map((participant) => {
    const [firstName, ...lastNameParts] = participant.name.split(" ");
    const lastName = lastNameParts.join(" ");
    return {
      participantFirstName: firstName,
      participantLastName: lastName,
      participantPhoneNumber: participant.phoneNumber,
    };
  });

  const [selectedParticipants, setSelectedParticipants] = useState<
    {
      participantFirstName: string;
      participantLastName: string;
      participantPhoneNumber: string;
    }[]
  >([]);
  const [bulkCheckIn] = useBulkEventCheckInMutation();

  const handleCheckIn = async (e) => {
    e.preventDefault();
    try {
      if (selectedParticipants.length === 0) {
        toast.error("Please select a participant.");
        return;
      }
      const response = await bulkCheckIn({
        eventId: eventData?.id,
        participants: selectedParticipants,
      });

      const { code, message } = response["data"];
      if (code === "00") {
        toast.success(message);
        setSelectedParticipants([]);
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error during bulk check-in:", error);
    }
  };

  const bulkOptions = participants.map((participant, index) => ({
    value: index,
    label: `${participant.participantFirstName} ${participant.participantLastName}`,
    ...participant,
  }));

  const handleSelectChange = (options: MultiValue<any>) => {
    setSelectedParticipants(options.map((option) => option));
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      border: "none",
      borderRadius: "2rem",
    }),
  };

  return (
    <div>
      <Col className="mt-3">
        <Select
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="Bulk Check-in Events"
          options={bulkOptions}
          styles={customStyles}
          isMulti
          value={selectedParticipants}
          onChange={handleSelectChange}
        />
      </Col>
      <Row className="mt-2">
        <Col className="d-grid">
          <Button
            className="rounded-5"
            style={{ fontSize: "12px" }}
            onClick={handleCheckIn}
          >
            Check In
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default BulkCheckIn;
