import { ChangeEvent, useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { CiSquareQuestion } from "react-icons/ci";
import { IoIosAdd } from "react-icons/io";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useNavigate } from "react-router-dom";
import { counsellingType } from "../../../../../../models/request/counselling-request";
import { useCounsellingTypeListQuery } from "../../../../../../services/counselling-service";

const RequestCounsellingModal = () => {
  const navigate = useNavigate();
  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const [phoneNumber] = useState(sessionStorage.getItem("phoneNumber"));
  const [firstName] = useState(sessionStorage.getItem("firstName"));
  const [lastName] = useState(sessionStorage.getItem("lastName"));
  const userId = parseInt(storedUserId);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [preferredStartAt, setPreferredStartAt] = useState<Date | null>(null);
  const [preferredEndAt, setPreferredEndAt] = useState<Date | null>(null);
  const [counselData, setCounselData] = useState<counsellingType>({
    requestType: "",
    formId: null,
    requestedBy: [
      {
        userId: userId,
        phone: phoneNumber,
        firstName: firstName,
        lastName: lastName,
      },
    ],
    preferredStartAt: "",
    preferredEndAt: "",
  });

  const handleFormChanged = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setCounselData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, -5); // Remove .000Z
      setPreferredStartAt(date);
      setCounselData((prevFormData) => ({
        ...prevFormData,
        preferredStartAt: formattedDate,
      }));
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, -5); // Remove .000Z
      setPreferredEndAt(date);
      setCounselData((prevFormData) => ({
        ...prevFormData,
        preferredEndAt: formattedDate,
      }));
    }
  };

  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [pageSize] = useState(10000000);

  const { data: response, isLoading } = useCounsellingTypeListQuery({
    page_index: currentPageIndex,
    page_size: pageSize,
  });

  const typeList = response?.data;

  return (
    <>
      <Button
        variant="dark"
        className="rounded-5 "
        style={{ fontSize: "12px" }}
        onClick={handleShow}
      >
        Request counselling <IoIosAdd />
      </Button>

      <Modal className="border-0" show={show} onHide={handleClose}>
        <Modal.Header className="border-0  mx-4 my-2" closeButton>
          <Modal.Title className="fs-6">Request Counselling</Modal.Title>
        </Modal.Header>

        <Modal.Body className="mx-4 my-0 py-0">
          <Form>
            {/* <Form.Label style={{ fontSize: "14px" }}>
              Counselling Type
            </Form.Label> */}
            <Form.Select
              size="lg"
              className="p-3 mb-3"
              aria-label="Default select example"
              name="requestType"
              onChange={handleFormChanged}
            >
              <option className="text-muted" value="" selected disabled>
                Select Counselling
              </option>
              {typeList?.map((type) => (
                <option key={type.value} value={type.name}>
                  {type.name}
                </option>
              ))}
            </Form.Select>
          </Form>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateTimePicker"]}>
              <Row>
                <DateTimePicker
                  label="Start date & time"
                  value={preferredStartAt}
                  onChange={handleStartDateChange}
                />
              </Row>
              <Row>
                <DateTimePicker
                  label="End date & time"
                  value={preferredEndAt}
                  onChange={handleEndDateChange}
                />
              </Row>
            </DemoContainer>
          </LocalizationProvider>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="dark"
            className="rounded-5"
            style={{ fontSize: "14px" }}
            onClick={() =>
              navigate("/e-counselling/approval", { state: { counselData } })
            }
          >
            Request <CiSquareQuestion />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RequestCounsellingModal;
