import { EventParticipants } from "../../../../../../models/request/create-event.request";
import { useCreateEventParticipantsMutation } from "../../../../../../services/event-service";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { HiPlus } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const EventParticipate = ({ eventId }) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const userId = parseInt(storedUserId);

  const [firstName] = useState(sessionStorage.getItem("firstName"));
  const [lastName] = useState(sessionStorage.getItem("lastName"));
  const fullName = `${firstName} ${lastName}`;
  const [phoneNumber] = useState(sessionStorage.getItem("phoneNumber"));

  const [eventParticipate, setEventParticipate] = useState<EventParticipants>({
    name: fullName,
    userId: userId,
    eventId: eventId,
    phoneNumber: phoneNumber,
  });

  const [participate] = useCreateEventParticipantsMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await participate(eventParticipate);
      const { code, message } = response["data"];
      console.log("response", response);
      if (code === "00") {
        toast.success("Event registration successful");
        navigate(`/events/event-details/${eventId}`);
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Button
        onClick={handleShow}
        variant="dark event-button fs-6 rounded-5 text-nowrap"
      >
        Register
        <HiPlus className="ms-2 d-inline-flex" />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "16px" }}>Register event</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "14px" }}>
          Are you sure you want to register this event?
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button
            style={{ fontSize: "14px" }}
            variant="dark"
            className="rounded-5"
            onClick={handleFormSubmit}
          >
            Register
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EventParticipate;
