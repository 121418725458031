import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { assignType } from "../../../../../../models/request/create-user.request";
import { useRevokeRolesMutation } from "../../../../../../services/auth-service";

const RevokeRole = ({ revoke }) => {
  const [show, setShow] = useState(false);
  const [selectedRoleName] = useState(sessionStorage.getItem("user_roleName"));
  const [revokeData] = useState<assignType>({
    username: revoke.username,
    roleName: selectedRoleName,
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [assignRoleMutation] = useRevokeRolesMutation();

  const handleCreateRole = async (e) => {
    e.preventDefault();
    try {
      const response = await assignRoleMutation(revokeData);
      const { code, message } = response["data"];
      if (code === 200) {
        toast.success(message);
      } else {
        toast.error(message || "Something went wrong");
      }
      handleClose();
    } catch (error) {
      toast.error(error.message);
      console.error("Error creating region:", error);
    }
  };

  return (
    <>
      <Button
        className="py-2 mx-1 rounded-5 "
        variant="danger"
        style={{ fontSize: "14px" }}
        onClick={handleShow}
      >
        Revoke Role
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Revoke role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to revoke this user?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="rounded-5"
            variant="secondary"
            onClick={handleClose}
            style={{ fontSize: "14px" }}
          >
            Close
          </Button>
          <Button
            style={{ fontSize: "14px" }}
            className="rounded-5"
            variant="primary"
            onClick={handleCreateRole} // Call handleCreateRegion on button click
          >
            Revoke
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RevokeRole;
