import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppEvent } from "../models/response/app-event";
import { AppConstants } from "../core/mocks/app-constants";
import { BaseResponse } from "../models/response/base-response";
import {
  bulkCheckinType,
  bulkParticipantType,
  checkinType,
  CreateEvent,
  districtType,
  editDistrictType,
  EditEvent,
  editInterventionType,
  editMediaHouseType,
  editPartnerType,
  editRegionType,
  EventParticipants,
  interventionType,
  mediaHouseType,
  partnerType,
  regionType,
  reviewType,
} from "../models/request/create-event.request";

export const eventService = createApi({
  reducerPath: "eventService",
  baseQuery: fetchBaseQuery({
    baseUrl: `${AppConstants.eventBaseUrl}`,
  }),
  tagTypes: ["Events"],
  endpoints: (build) => ({
    createEvent: build.mutation<BaseResponse<any>, CreateEvent>({
      query: (body: CreateEvent) => ({
        url: `/events`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    editEvent: build.mutation<BaseResponse<AppEvent>, EditEvent>({
      query: (body: EditEvent) => ({
        url: `/events`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    getEventList: build.query<
      BaseResponse<AppEvent[]>,
      { page: number; size: number }
    >({
      query: ({ page, size }) => ({
        url: `/events/list?page=${page}&size=${size}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    getEventById: build.query<BaseResponse<AppEvent>, number>({
      query: (id) => ({
        url: `/events/${id}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    deleteEventById: build.mutation<BaseResponse<AppEvent>, number>({
      query: (id) => ({
        url: `/events/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Events"],
    }),
    createEventPartner: build.mutation<BaseResponse<AppEvent>, partnerType>({
      query: (body: partnerType) => ({
        url: `/event-partners`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),

    deleteEventPartnerById: build.mutation<BaseResponse<AppEvent>, number>({
      query: (id) => ({
        url: `/event-partners/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Events"],
    }),

    updateEventPartner: build.mutation<BaseResponse<AppEvent>, editPartnerType>(
      {
        query: (body: editPartnerType) => ({
          url: `/event-partners`,
          method: "PUT",
          body: body,
        }),
        invalidatesTags: ["Events"],
      }
    ),
    getEventPartnersList: build.query<
      BaseResponse<AppEvent[]>,
      { page: number; size: number }
    >({
      query: ({ page, size }) => ({
        url: `/event-partners/list?page=${page}&size=${size}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    createRegion: build.mutation<BaseResponse<AppEvent>, regionType>({
      query: (body: regionType) => ({
        url: `/regions`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),

    deleteEventRegionById: build.mutation<BaseResponse<AppEvent>, number>({
      query: (regionid) => ({
        url: `/regions/${regionid}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Events"],
    }),

    updateRegion: build.mutation<BaseResponse<AppEvent>, editRegionType>({
      query: (body: editRegionType) => ({
        url: `/regions`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    getEventRegionList: build.query<
      BaseResponse<AppEvent[]>,
      { page: number; size: number }
    >({
      query: ({ page, size }) => ({
        url: `/regions/list?page=${page}&size=${size}`,

        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    createDistrict: build.mutation<BaseResponse<AppEvent>, districtType>({
      query: (body: districtType) => ({
        url: `/districts`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    deleteEventDistrictById: build.mutation<BaseResponse<AppEvent>, number>({
      query: (districtId) => ({
        url: `/districts/${districtId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Events"],
    }),

    updateDistrict: build.mutation<BaseResponse<AppEvent>, editDistrictType>({
      query: (body: editDistrictType) => ({
        url: `/districts`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    getEventDistrictList: build.query<
      BaseResponse<AppEvent[]>,
      { page: number; size: number }
    >({
      query: ({ page, size }) => ({
        url: `/districts/list?page=${page}&size=${size}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    getEventMediaHouseList: build.query<
      BaseResponse<AppEvent[]>,
      { page: number; size: number }
    >({
      query: ({ page, size }) => ({
        url: `/event-media-houses/list?page=${page}&size=${size}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    createEventMediaHouse: build.mutation<
      BaseResponse<AppEvent[]>,
      mediaHouseType
    >({
      query: (body: mediaHouseType) => ({
        url: `/event-media-houses`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    updateEventMediaHouse: build.mutation<
      BaseResponse<AppEvent[]>,
      editMediaHouseType
    >({
      query: (body: editMediaHouseType) => ({
        url: `/event-media-houses`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    deleteEventMediaHouse: build.mutation<BaseResponse<AppEvent[]>, number>({
      query: (id) => ({
        url: `/event-media-houses/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Events"],
    }),
    getEventParticipantsList: build.query<
      BaseResponse<AppEvent[]>,
      { page: number; size: number }
    >({
      query: ({ page, size }) => ({
        url: `/event-participants/list?page=${page}&size=${size}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    getDistrictByRegionId: build.query<BaseResponse<AppEvent>, number>({
      query: (regionId) => ({
        url: `/districts/list/${regionId}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    createEventParticipants: build.mutation<
      BaseResponse<AppEvent>,
      EventParticipants
    >({
      query: (body: EventParticipants) => ({
        url: `/event-participants`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    createBulkParticipants: build.mutation<
    BaseResponse<AppEvent>,
    bulkParticipantType
  >({
    query: (body: bulkParticipantType) => ({
      url: `/event-participants/bulk`,
      method: "POST",
      body: body,
    }),
    invalidatesTags: ["Events"],
  }),
    deleteEventParticipants: build.mutation<
      BaseResponse<AppEvent>,
      { eventId: number; participantId: number }
    >({
      query: ({ eventId, participantId }) => ({
        url: `/event-participants/${eventId}/${participantId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Events"],
    }),
    getOngoingEvent: build.query<
      BaseResponse<AppEvent[]>,
      { page: number; size: number; title: string }
    >({
      query: ({ page, size, title }) => ({
        url: `/events/filter?page=${page}&size=${size}&status=STARTED&sortOrder=DESC&sortBy=createdOn&title=${title}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    getUserOngoingEvent: build.query<
      BaseResponse<AppEvent[]>,
      { page: number; size: number; title: string; userId: number }
    >({
      query: ({ page, size, title, userId }) => ({
        url: `/events/filter?page=${page}&size=${size}&status=STARTED&sortOrder=DESC&sortBy=createdOn&title=${title}&userId=${userId}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    getUpcomingEvent: build.query<
      BaseResponse<AppEvent[]>,
      { page: number; size: number; title: string }
    >({
      query: ({ page, size, title }) => ({
        url: `/events/filter?page=${page}&size=${size}&sortOrder=DESC&sortBy=createdOn&status=UPCOMING&title=${title}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    getEndedEvent: build.query<
      BaseResponse<AppEvent[]>,
      { page: number; size: number; title: string }
    >({
      query: ({ page, size, title }) => ({
        url: `/events/filter?page=${page}&size=${size}&sortOrder=DESC&sortBy=createdOn&status=ENDED&title=${title}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    getUserEndedEvent: build.query<
      BaseResponse<AppEvent[]>,
      { page: number; size: number; title: string; userId: number }
    >({
      query: ({ page, size, title, userId }) => ({
        url: `/events/filter?page=${page}&size=${size}&status=ENDED&sortOrder=DESC&sortBy=createdOn&title=${title}&userId=${userId}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    getRegisteredEventList: build.query<
      BaseResponse<AppEvent[]>,
      { userId: number }
    >({
      query: ({ userId }) => ({
        url: `/events/list/event/${userId}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    eventCheckIn: build.mutation<BaseResponse<AppEvent>, checkinType>({
      query: (body: checkinType) => ({
        url: `/event-check-in`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    bulkEventCheckIn: build.mutation<BaseResponse<AppEvent>, bulkCheckinType>({
      query: (body: bulkCheckinType) => ({
        url: "/event-check-in/bulk",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    getExpectationsByPhoneNumber: build.query<
      BaseResponse<AppEvent[]>,
      {
        page: number;
        size: number;
        participantPhoneNumber: string;
        event: string;
      }
    >({
      query: ({ page, size, participantPhoneNumber, event }) => ({
        url: `/event-expectation/filter?page=${page}&size=${size}&participantPhoneNumber=${participantPhoneNumber}&event=${event}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    getReviewsByPhoneNumber: build.query<
      BaseResponse<AppEvent[]>,
      {
        page: number;
        size: number;
        participantPhoneNumber: string;
        event: string;
      }
    >({
      query: ({ page, size, participantPhoneNumber, event }) => ({
        url: `/event-review/filter?page=${page}&size=${size}&participantPhoneNumber=${participantPhoneNumber}&event=${event}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    createExpectations: build.mutation<BaseResponse<AppEvent>, reviewType>({
      query: (body: reviewType) => ({
        url: `/event-expectation`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    createReviews: build.mutation<BaseResponse<AppEvent>, reviewType>({
      query: (body: reviewType) => ({
        url: `/event-review`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    getAllReviews: build.query<
      BaseResponse<AppEvent>,
      { page: number; size: number; event: string }
    >({
      query: ({ page, size, event }) => ({
        url: `/event-review/list?page=${page}&size=${size}&event=${event}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    createIntervention: build.mutation<BaseResponse<AppEvent>, interventionType>({
      query: (body: interventionType) => ({
        url: "/event-intervention",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    getIntervention: build.query<BaseResponse<AppEvent>,  {page: number; size: number;}>({
    query: ({ page, size }) => ({
      url: `/event-intervention?page=${page}&size=${size}`,
      method: "GET",
    }),
    providesTags: ["Events"],
  }),
  editIntervention: build.mutation<BaseResponse<AppEvent>, editInterventionType>({
    query: (body: editInterventionType) => ({
      url: "/event-intervention",
      method: "PUT",
      body: body,
    }),
    invalidatesTags: ["Events"],
  }),
  deleteIntervention: build.mutation<BaseResponse<AppEvent>, number>({
  query: (id) => ({
    url: `/event-intervention/${id}`,
    method: "DELETE",
  }),
  invalidatesTags: ["Events"],
}),
  }),
});

export const {
  useCreateEventMutation,
  useGetEventListQuery,
  useGetEventByIdQuery,
  useDeleteEventByIdMutation,
  useEditEventMutation,
  useGetEventPartnersListQuery,
  useCreateEventPartnerMutation,
  useUpdateEventPartnerMutation,
  useDeleteEventPartnerByIdMutation,
  useCreateRegionMutation,
  useGetEventRegionListQuery,
  useDeleteEventRegionByIdMutation,
  useUpdateRegionMutation,
  useCreateDistrictMutation,
  useGetEventDistrictListQuery,
  useUpdateDistrictMutation,
  useDeleteEventDistrictByIdMutation,
  useGetEventMediaHouseListQuery,
  useCreateEventMediaHouseMutation,
  useUpdateEventMediaHouseMutation,
  useDeleteEventMediaHouseMutation,
  useGetEventParticipantsListQuery,
  useGetDistrictByRegionIdQuery,
  useCreateEventParticipantsMutation,
  useDeleteEventParticipantsMutation,
  useGetUpcomingEventQuery,
  useGetOngoingEventQuery,
  useGetEndedEventQuery,
  useGetRegisteredEventListQuery,
  useEventCheckInMutation,
  useBulkEventCheckInMutation,
  useGetExpectationsByPhoneNumberQuery,
  useCreateExpectationsMutation,
  useCreateReviewsMutation,
  useGetAllReviewsQuery,
  useGetReviewsByPhoneNumberQuery,
  useGetUserOngoingEventQuery,
  useGetUserEndedEventQuery,
  useCreateInterventionMutation,
  useGetInterventionQuery,
  useEditInterventionMutation,
  useDeleteInterventionMutation,
  useCreateBulkParticipantsMutation
} = eventService;
