import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { editMediaHouseType } from "../../../../../../models/request/create-event.request";
import { useUpdateEventMediaHouseMutation } from "../../../../../../services/event-service";

const EditMediaHouseModal = ({ mediaHouseData }) => {
  const [show, setShow] = useState(false);
  const [mediaData, setMediaData] = useState<editMediaHouseType>({
    id: mediaHouseData.id,
    name: mediaHouseData.name,
    category: mediaHouseData.category,
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setMediaData((prevRegionData) => ({
      ...prevRegionData,
      [name]: value,
    }));
  };

  const handleFormSelectChanged: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    const { name, value } = e.target;
    setMediaData((prevEventData) => ({
      ...prevEventData,
      [name]: value,
    }));
  };

  const [updateMediaHouseMutation] = useUpdateEventMediaHouseMutation();

  const handleEditMediaHouse = async (e) => {
    e.preventDefault();
    try {
      const response = await updateMediaHouseMutation(mediaData);
      const { code, message } = response["data"];
      if (code === "00") {
        toast.success(message);
      } else {
        toast.error(message || "Something went wrong");
      }
      handleClose();
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setMediaData((prev) => ({
      ...prev,
      id: mediaHouseData.id,
      name: mediaHouseData.name,
      category: mediaHouseData.category,
    }));
  }, []);

  return (
    <>
      <Button
        className="py-2 rounded-5 mx-auto"
        variant="light"
        style={{ fontSize: "12px", background: "rgba(25, 22, 29, 0.06)" }}
        onClick={handleShow}
      >
        Edit Media House
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Edit a media house</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label style={{ fontSize: "14px" }}>Category</Form.Label>
            <Form.Select
              className="p-2"
              aria-label="Default select example"
              name="category"
              value={mediaData.category}
              onChange={handleFormSelectChanged}
            >
              <option className="text-muted" value="" selected disabled>
                Select category
              </option>
              <option value="TV">TV</option>
              <option value="RADIO">Radio</option>
              <option value="COMMUNICATION_INFORMATION_CENTRE">
                Communication Information Centre
              </option>
              <option value="INFORMATION_VAN">Information Van</option>
              <option value="OTHER">Other</option>
            </Form.Select>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: "14px" }}>
                Media house name
              </Form.Label>
              <Form.Control
                className="p-2"
                type="text"
                placeholder="Enter media house name"
                autoFocus
                name="name"
                value={mediaData.name}
                onChange={handleFormChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="rounded-5"
            variant="secondary"
            onClick={handleClose}
            style={{ fontSize: "14px" }}
          >
            Close
          </Button>
          <Button
            style={{ fontSize: "14px" }}
            className="rounded-5"
            variant="primary"
            onClick={handleEditMediaHouse}
          >
            Update Media House
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditMediaHouseModal;
