import { GridColDef } from "@mui/x-data-grid";
import { Badge } from "react-bootstrap";
import { TbUsers } from "react-icons/tb";
import { VscBook } from "react-icons/vsc";
import { PiSuitcaseSimple } from "react-icons/pi";
import { Image} from "react-bootstrap";
import ViewAssignedRequest from "./ViewAssignedRequest";

const AssignedTableElements: GridColDef[] = [
  {
    field: "id",
    headerName: "Id",
    width: 70,
    headerClassName: "header--bg",
  },

  {
    field: "requestType",
    headerName: "Request Type",
    width: 120,
    headerClassName: "header--bg",
    renderCell: (params: any) => {
      let icon = null;
      switch (params?.row?.requestType) {
        case "RELATIONSHIP":
          icon = <TbUsers />;
          break;
        case "EDUCATION":
          icon = <VscBook />;
          break;
        case "CAREER":
          icon = <PiSuitcaseSimple />;
          break;
        default:
          icon = null;
          break;
      }
      return (
        <div>
          <Badge className="bg-pill fw-medium" pill text="dark">
            {icon} {params?.row?.requestType}
          </Badge>
        </div>
      );
    },
  },

  {
    field: "requestedBy",
    headerName: "Requested By",
    headerClassName: "header--bg",
    width: 180,
    renderCell: (params: any) => (
      <>
        <Image
          src={`https://ui-avatars.com/api/?name=${params?.row?.requestedBy[0]?.firstName}+${params?.row?.requestedBy[0].lastName}&background=random&size=32`}
          alt="Avatar"
          roundedCircle
          className="me-2"
        />
        {params?.row?.requestedBy[0]?.firstName}{" "}
        {params?.row?.requestedBy[0]?.lastName}
      </>
    ),
  },

  {
    field: "preferredStartAndEnd",
    headerName: "Preferred Duration",
    width: 250,
    headerClassName: "header--bg",
    renderCell: (params: any) => (
      <div>
        <Badge className="bg-pill fw-normal" pill text="dark">
          Start Date:{" "}
          {params?.row?.preferredStartAt
            ? new Date(params?.row?.preferredStartAt).toLocaleDateString()
            : ""}{" "}
          -{" "}
          {params?.row?.preferredStartAt
            ? new Date(params?.row?.preferredStartAt).toLocaleTimeString()
            : ""}
        </Badge>
        <br />
        <Badge className="bg-pill fw-normal" pill text="dark">
          End Date:{" "}
          {params?.row?.preferredEndAt
            ? new Date(params?.row?.preferredEndAt).toLocaleDateString()
            : ""}{" "}
          -{" "}
          {params?.row?.preferredEndAt
            ? new Date(params?.row?.preferredEndAt).toLocaleTimeString()
            : ""}
        </Badge>
      </div>
    ),
  },

  {
    field: "confirmation",
    headerName: "Counsellors",
    headerClassName: "header--bg",
    width: 230,
    renderCell: (params: any) => (
      <>
        {params?.row?.confirmation?.counsellors.length === 1 ? (
          // If there is only one counselor
          <div key={params.row.confirmation.counsellors[0].id}>
            <Image
              src={`https://ui-avatars.com/api/?name=${params.row.confirmation.counsellors[0].firstName}+${params.row.confirmation.counsellors[0].lastName}&background=random&size=32`}
              alt="Avatar"
              roundedCircle
              className="me-2"
            />
            {params.row.confirmation.counsellors[0].firstName} {params.row.confirmation.counsellors[0].lastName}
          </div>
        ) : (
          // If there are multiple counselors
          params.row.confirmation.counsellors.map((counsellor: any) => (
            <Image
              key={counsellor.id}
              src={`https://ui-avatars.com/api/?name=${counsellor.firstName}+${counsellor.lastName}&background=random&size=32`}
              alt="Avatar"
              roundedCircle
              className="me-2"
            />
          ))
        )}
      </>
    ),
  },
  
  

  {
    field: "createdOn",
    headerName: "Date Created",
    headerClassName: "header--bg",
    width: 120,
    valueFormatter: (params: any) =>
      new Date(params.value).toLocaleDateString(),
  },

  {
    field: "actions",
    headerName: "Actions",
    headerClassName: "header--bg",
    flex: 1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      console.log("params", params);
      return (
        <div>
          <ViewAssignedRequest assignedData={params.row} />
        </div>
      );
    },
  },
];

export default AssignedTableElements;
