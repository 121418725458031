import { GridColDef } from "@mui/x-data-grid";
import ViewEnquiriesModal from "./ViewEnquiriesModal";
import { Image } from "react-bootstrap";

const EnquiriesTableElements: GridColDef[] = [
  {
    field: "id",
    headerName: "Id",
    // flex: 1,
    headerClassName: "header--bg",
  },
  {
    field: "name",
    headerName: "Name",
    headerClassName: "header--bg",
    flex: 1,
    renderCell: (params: any) => (
      <>
        <Image
          src={`https://ui-avatars.com/api/?name=${params?.row?.name}&background=random&size=32`}
          alt="Avatar"
          roundedCircle
          className="me-2"
        />
        {params?.row?.name || ""}
      </>
    ),
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    headerClassName: "header--bg",
  },
  {
    field: "phoneNumber",
    headerName: "phoneNumber",
    flex: 1,
    headerClassName: "header--bg",
  },

  {
    field: "enquiryNumber",
    headerName: "Enquiry Number",
    flex: 1,
    headerClassName: "header--bg",
  },

  {
    field: "enquiryClosed",
    headerName: "Enquiry status",
    flex: 1,
    headerClassName: "header--bg",
    renderCell: (params) => {
      const status = params.value ? "Closed" : "Open";
      const colorClass = params.value ? "text-success" : "text-danger";
      return (
        <span className={colorClass}>
          {status}
        </span>
      );
    },
  },
  
  {
    field: "createdAT",
    headerName: "Date Created",
    headerClassName: "header--bg",
    flex: 1,
    valueFormatter: (params: any) =>
      new Date(params.value).toLocaleDateString(),
  },

  {
    field: "actions",
    headerName: "Actions",
    headerClassName: "header--bg",
    flex: 1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      console.log("params", params);
      return (
        <div>
          <ViewEnquiriesModal enquiryData={params.row} />
        </div>
      );
    },
  },
];

export default EnquiriesTableElements;
