import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDeleteCounsellorMutation } from "../../../../../../services/counselling-service";

const DeleteMentor = ({ userId }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [deleteCounsellor] = useDeleteCounsellorMutation();

  const handleCreateRole = async (e) => {
    e.preventDefault();
    try {
      const response = await deleteCounsellor(userId);
      const { code, message } = response["data"];
      if (code === 0) {
        toast.success(message);
      } else {
        toast.error(message || "Something went wrong");
      }
      handleClose();
    } catch (error) {
      toast.error(error.message);
      console.error("Error creating region:", error);
    }
  };

  return (
    <>
      <Button
        className="py-2 mx-1 rounded-5 "
        variant="danger"
        style={{ fontSize: "12px" }}
        onClick={handleShow}
      >
       Delete
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Delete mentor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this mentor?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="rounded-5"
            variant="secondary"
            onClick={handleClose}
            style={{ fontSize: "14px" }}
          >
            Close
          </Button>
          <Button
            style={{ fontSize: "14px" }}
            className="rounded-5"
            variant="primary"
            onClick={handleCreateRole} // Call handleCreateRegion on button click
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteMentor;
