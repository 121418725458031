import React from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import GroupTopic from "./groupTopic/GroupTopic";
import CreateGroupTopic from "./groupTopic/CreateGroupTopic";
import CreateGroupChannel from "./groupChannels/CreateGroupChannel";
import GroupChannels from "./groupChannels/GroupChannels";
import moment from "moment";
import ViewMembers from "./groupMembers/ViewMembers";
import CreateGroupAnnouncement from "./groupAnnouncement/CreateGroupAnnouncement";
import Announcement from "./groupAnnouncement/Announcement";
import GroupEvents from "./groupEvents/GroupEvents";

const GroupDetailsBody = ({ groupData }) => {
  const roles = sessionStorage.getItem("roles");
  const allowedRoles = ["Admin", "Super Admin", "Mentor"];
  const hasAllowedRole =
    roles && allowedRoles.some((role) => roles.includes(role));
  const canEditGroup =
    roles && ["Admin", "Super Admin"].some((role) => roles.includes(role));

  return (
    <Container>
      <Row className="p-4">
        <Col>
          <h1 className="fs-4">{groupData?.name}</h1>
          {canEditGroup && (
            <Link to={`/e-mentoring/edit-group/${groupData?.id}`}>
              <Button
                style={{ fontSize: "14px" }}
                variant="dark"
                className="rounded-5 my-3"
              >
                Edit Group
              </Button>
            </Link>
          )}
          <p className="fs-6">{groupData?.members?.length} members</p>
          {hasAllowedRole && (
            <>
              <ViewMembers
                groupMembers={groupData?.members}
                groupId={groupData?.id}
              />
            </>
          )}
          <h1 className="fs-6 fw-semibold mt-4">Group Summary</h1>
          <p className="fs-6">{groupData?.description}</p>
          {hasAllowedRole && (
            <div className="my-2">
              <h1 className="fs-6 fw-semibold mt-4">Group Topic</h1>
              <CreateGroupTopic groupID={groupData?.id} />
            </div>
          )}
          <GroupTopic groupTopic={groupData?.topics} />
          <h1 className="fs-6 fw-semibold mt-4">Our Channels</h1>
          {hasAllowedRole && (
            <div className="my-2">
              <CreateGroupChannel groupID={groupData?.id} />
            </div>
          )}
          <GroupChannels groupChannel={groupData?.socialLinks} />
        </Col>
        <Col>
          <h1 className="fs-6 fw-semibold">Mentors</h1>
          {groupData?.mentors?.length === 0 ? (
            <div>No mentors available</div>
          ) : (
            groupData?.mentors?.map((mentor) => (
              <div className="flex me-3 flex-wrap" key={mentor.id}>
                <Image
                  src={`https://ui-avatars.com/api/?name=${mentor?.user?.firstName}+${mentor?.user?.lastName}&background=random&size=32`}
                  alt="Avatar"
                  roundedCircle
                  className="me-2"
                />
                {mentor?.user?.firstName || ""} {mentor?.user?.lastName || ""}
              </div>
            ))
          )}
          <p className="fs-6 my-3">
            Date Created: {moment(groupData?.createdOn).format("MMMM Do YYYY")}
          </p>
          <h1 className="fs-6 mt-4 fw-semibold">Announcements</h1>
          {hasAllowedRole && (
            <div className="my-2">
              <CreateGroupAnnouncement groupID={groupData?.id} />
            </div>
          )}
          <Announcement groupAnnouncement={groupData?.announcements} />
        </Col>
      </Row>
      <Row className="p-4">
        <h1 className="fs-6 fw-semibold">Group Event</h1>
        {hasAllowedRole && (
        <Link
          className="text-white"
          to={`/e-mentoring/create-group-events/${groupData?.id}`}
        >
          <Button
            className="py-2 rounded-5 mx-auto"
            variant="dark"
            style={{ fontSize: "12px", fontWeight: "400" }}
          >
            Create group event +
          </Button>
        </Link>
        )}
        <GroupEvents groupID={groupData?.id} />
      </Row>
    </Container>
  );
};

export default GroupDetailsBody;
