import Chart from "./components/Chart";
import Stats from "./components/Stats";
import RegisteredEventCard from "../events/eventMain/registerEvents/RegisteredEventCard";
import ProtectedRoutes from "../../auth/utils/ProtectedRoutes";

const Dashboard = () => {
  const roles = sessionStorage.getItem("roles");
  const isAdmin = roles.includes("Super Admin") || roles.includes("Admin");
  return (
    <div className="p-3 rounded-start-4">
      {isAdmin ? (
        <div>
          <h1 className="text-black fs-4 py-2">Dashboard</h1>
          <Stats />
          <Chart />
        </div>
      ) : (
        <div>
          <h1 className="text-black fs-4 py-2">My springboard</h1>
          <RegisteredEventCard />
        </div>
      )}
    </div>
  );
};

export default ProtectedRoutes(Dashboard, {
  allowedRoles: [
    "Super Admin",
    "Admin",
    "User",
    "Counsellor",
    "Mentor",
    "Usher",
    "Facilitator",
    "Call Center Manager",
    "Coordinator",
  ],
});
