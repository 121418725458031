import React, { useState } from "react";
import { Nav, Accordion, Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { SidebarMenus } from "../../core/mocks/sidebar-mocks";
import ProfileDropDown from "./ProfileDropDown";
import { TbTimelineEventText } from "react-icons/tb";
import { FaUsersCog } from "react-icons/fa";
import { Ecounselling, Ementoring } from "../../presentation/assets";

const Sidebar: React.FC = () => {
  const {
    firstMenus,
    secondMenus,
    eventMenus,
    userMenus,
    counsellingMenus,
    mentoringMenus,
  } = SidebarMenus;
  const roles = sessionStorage.getItem("roles");

  const isAdmin = roles && roles.includes("Admin");
  const isSuperAdmin = roles && roles.includes("Super Admin");
  const isCoordinator = roles && roles.includes("Coordinator");
  const isCallCentre = roles && roles.includes("Call Center Manager");
  const isCounsellor = roles && roles.includes("Counsellor");
  const isMentor = roles && roles.includes("Mentor");

  const filteredFirstMenus = firstMenus.filter((menuItem) => {
    return (
      isAdmin || isCallCentre || isCoordinator || menuItem.url !== "/users"
    );
  });

  const filteredSecondMenus = secondMenus.filter((menuItem) => {
    if (isAdmin || isSuperAdmin) {
      return menuItem.url !== "/users" && menuItem.url !== "/enquiries/make";
    }

    return menuItem.url !== "/enquiries/list" && menuItem.url !== "/users";
  });

  const filteredEventMenus =
    isSuperAdmin || isAdmin
      ? eventMenus
      : eventMenus.filter((menuItem) => menuItem.url === "/events/events");

  const filteredUserMenus = userMenus.filter((menuItem) => {
    if (isSuperAdmin || isAdmin) {
      return true;
    }
    if (isCoordinator || isCallCentre) {
      return menuItem.url !== "/users/roles";
    }
    return true;
  });

  const filteredCounsellingMenus = counsellingMenus.filter(
    (counselMenuItem) => {
      if (isAdmin || isSuperAdmin) {
        return (
          counselMenuItem.url === "/e-counselling/list" ||
          counselMenuItem.url === "/e-counselling/counselling-type" ||
          counselMenuItem.url === "/e-counselling/add-counsellor"
        );
      }
      if (isCounsellor) {
        return (
          counselMenuItem.url ===
          "/e-counselling/counsellor-availability-calender"
        );
      }
      return counselMenuItem.url === "/e-counselling/request";
    }
  );

  const filteredMentoringMenus = mentoringMenus.filter((mentorMenuItem) => {
    if (isAdmin || isSuperAdmin) {
      return (
        mentorMenuItem.url === "/e-mentoring/list" ||
        mentorMenuItem.url === "/e-mentoring/groups" ||
        mentorMenuItem.url === "/e-mentoring/add-mentor"
      );
    }
    if (isMentor) {
      return (
        mentorMenuItem.url === "/e-mentoring/mentor-calender" ||
        mentorMenuItem.url === "/e-mentoring/groups"
      );
    }
    return mentorMenuItem.url === "/e-mentoring/groups";
  });

  const [eventAccordionOpen, setEventAccordionOpen] = useState<boolean>(false);
  const [userAccordionOpen, setUserAccordionOpen] = useState<boolean>(false);
  const [counselAccordionOpen, setCounselAccordionOpen] =
    useState<boolean>(false);
  const [mentorAccordionOpen, setMentorAccordionOpen] =
    useState<boolean>(false);

  const toggleEventAccordion = () => {
    setEventAccordionOpen(!eventAccordionOpen);
  };

  const toggleUserAccordion = () => {
    setUserAccordionOpen(!userAccordionOpen);
  };

  const toggleCounselAccordion = () => {
    setCounselAccordionOpen(!counselAccordionOpen);
  };

  const toggleMentorAccordion = () => {
    setMentorAccordionOpen(!mentorAccordionOpen);
  };

  return (
    <div className="d-flex flex-column py-3" style={{ minHeight: "100%" }}>
      <div>
        <ProfileDropDown />

        <Nav defaultActiveKey="/home" className="flex-column mt-2 sidebar-menu">
          {filteredFirstMenus.map((menuItem, index) => (
            <React.Fragment key={index}>
              {menuItem.name !== "Events" &&
                menuItem.name !== "Users" &&
                menuItem.name !== "E-counselling" && (
                  <Nav.Link as={NavLink} to={menuItem.url} className="mb-2">
                    {menuItem.icon}
                    <span className="ms-1 px-2 d-sm-inline">
                      {menuItem.name}
                    </span>
                  </Nav.Link>
                )}
              {menuItem.name === "Events" && (
                <Accordion
                  className="text-decoration-none"
                  activeKey={eventAccordionOpen ? "0" : ""}
                >
                  <Accordion.Item
                    className="border-0 bg-transparent"
                    eventKey="0"
                  >
                    <Accordion.Header
                      className="px-0 m-0 bg-transparent"
                      onClick={toggleEventAccordion}
                    >
                      <TbTimelineEventText />
                      <span
                        style={{ fontSize: "14px" }}
                        className="ms-1 px-2 d-sm-inline"
                      >
                        Event Management
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Nav className="flex-column">
                        {filteredEventMenus.map((eventMenuItem, index) => (
                          <Nav.Link
                            key={index}
                            as={NavLink}
                            to={eventMenuItem.url}
                            className="mb-2"
                          >
                            {eventMenuItem.icon}
                            <span className="ms-1 px-2 d-sm-inline">
                              {eventMenuItem.name}
                            </span>
                          </Nav.Link>
                        ))}
                      </Nav>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
              {menuItem.name === "Users" && (
                <Accordion
                  className="text-decoration-none"
                  activeKey={userAccordionOpen ? "0" : ""}
                >
                  <Accordion.Item
                    className="border-0 bg-transparent"
                    eventKey="0"
                  >
                    <Accordion.Header
                      className="px-0 m-0 bg-transparent"
                      onClick={toggleUserAccordion}
                    >
                      <FaUsersCog />
                      <span
                        style={{ fontSize: "14px" }}
                        className="ms-1 px-2 d-sm-inline"
                      >
                        User Management
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Nav className="flex-column">
                        {filteredUserMenus.map((userMenuItem, index) => (
                          <Nav.Link
                            key={index}
                            as={NavLink}
                            to={userMenuItem.url}
                            className="mb-2"
                          >
                            {userMenuItem.icon}
                            <span className="ms-1 px-2 d-sm-inline">
                              {userMenuItem.name}
                            </span>
                          </Nav.Link>
                        ))}
                      </Nav>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
              {menuItem.name === "E-counselling" && (
                <Accordion
                  className="text-decoration-none"
                  activeKey={counselAccordionOpen ? "0" : ""}
                >
                  <Accordion.Item
                    className="border-0 bg-transparent"
                    eventKey="0"
                  >
                    <Accordion.Header
                      className="px-0 m-0 bg-transparent"
                      onClick={toggleCounselAccordion}
                    >
                      <Image src={Ecounselling} alt="" />
                      <span
                        style={{ fontSize: "14px" }}
                        className="ms-1 px-2 d-sm-inline"
                      >
                        E-Counselling
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Nav className="flex-column">
                        {filteredCounsellingMenus.map(
                          (counselMenuItem, index) => (
                            <Nav.Link
                              key={index}
                              as={NavLink}
                              to={counselMenuItem.url}
                              className="mb-2"
                            >
                              {counselMenuItem.icon}
                              <span className="ms-1 px-2 d-sm-inline">
                                {counselMenuItem.name}
                              </span>
                            </Nav.Link>
                          )
                        )}
                      </Nav>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
            </React.Fragment>
          ))}
          <hr className="my-2" />
          {filteredSecondMenus.map((menuItem, index) => (
            <React.Fragment key={index}>
              {menuItem.name !== "E-mentoring" && (
                <Nav.Link as={NavLink} to={menuItem.url} className="mb-2">
                  {menuItem.icon}
                  <span className="ms-1 px-2 d-sm-inline">{menuItem.name}</span>
                </Nav.Link>
              )}
              {menuItem.name === "E-mentoring" && (
                <Accordion
                  className="text-decoration-none"
                  activeKey={mentorAccordionOpen ? "0" : ""}
                >
                  <Accordion.Item
                    className="border-0 bg-transparent"
                    eventKey="0"
                  >
                    <Accordion.Header
                      className="px-0 m-0 bg-transparent"
                      onClick={toggleMentorAccordion}
                    >
                      <Image src={Ementoring} alt="" />
                      <span
                        style={{ fontSize: "14px" }}
                        className="ms-1 px-2 d-sm-inline"
                      >
                        E-Mentoring
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Nav className="flex-column">
                        {filteredMentoringMenus.map((mentorMenuItem, index) => (
                          <Nav.Link
                            key={index}
                            as={NavLink}
                            to={mentorMenuItem.url}
                            className="mb-2"
                          >
                            {mentorMenuItem.icon}
                            <span className="ms-1 px-2 d-sm-inline">
                              {mentorMenuItem.name}
                            </span>
                          </Nav.Link>
                        ))}
                      </Nav>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
            </React.Fragment>
          ))}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
