
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ApprovedTableElements from "./ApprovedTableElements";
import { useApprovedRequestListQuery } from "../../../../../../../services/mentoring-service";
import PageLoader from "../../../../../../components/PageLoader";
import SharedPagination from "../../../../../../components/Pagination";

const ApprovedDataTable = ({ searchQuery }) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [pageSize] = useState(10);

  const { data: response, isLoading } = useApprovedRequestListQuery({
    page_index: currentPageIndex,
    page_size: pageSize,
  });

  const filteredApproveData =
    response?.data.filter((approval) =>
      Object.values(approval).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      )
    ) || [];

  const totalPages = response?.totalPages || 0;
  const totalElements = response?.totalElements || 0;
  return (
    <Container>
      <Row>
        <Col>
          <div className="w-100 h-auto">
            {isLoading ? (
              <PageLoader />
            ) : (
              <>
                {filteredApproveData.length === 0 ? (
                  <h3 className="text-black text-center">No data available</h3>
                ) : (
                  <DataGrid
                    rows={(filteredApproveData || []) as any[]}
                    columns={ApprovedTableElements}
                    checkboxSelection
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: false,
                      },
                    }}
                    sx={{
                      "& .header--bg": {
                        backgroundColor: "#EBF1FC",
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "#EBF1FC",
                        fontSize: "12px",
                      },
                      "& .Mui-checked .MuiSvgIcon-root": {
                        fill: "black",
                        background: "white",
                      },
                      "& .MuiSvgIcon-root": {
                        width: "18px",
                        height: "18px",
                        borderRadius: "4px",
                        color: "#EBEBEB",
                      },
                      "&.MuiDataGrid-root": {
                        border: "none",
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: "Inter",
                        backgroundColor: "white",
                      },

                      "& .MuiDataGrid-footerContainer": {
                        display: "none",
                      },
                    }}
                  />
                )}
                <SharedPagination
                  currentPage={currentPageIndex + 1}
                  onPageChange={(newPage) => setCurrentPageIndex(newPage - 1)}
                  totalPages={totalPages}
                  pageSize={pageSize}
                  totalElements={totalElements}
                />
              </>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ApprovedDataTable;
