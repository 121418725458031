import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Avatar from "react-avatar";
import { Link, useNavigate } from "react-router-dom";
import LeaveGroup from "./leaveGroup/LeaveGroup";
import { useGetMentorshipGroupByUserIDQuery } from "../../../../../../services/mentoring-service";
import PageLoader from "../../../../../components/PageLoader";
import SharedPagination from "../../../../../components/Pagination";


const MyMentorshipGroups = () => {
  const navigate = useNavigate();

  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const userId = parseInt(storedUserId);

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [pageSize] = useState(6);
  const { data: response, isLoading } = useGetMentorshipGroupByUserIDQuery({
    page_index: currentPageIndex,
    page_size: pageSize,
    userid: userId,
  });

  const mentorGroup = response?.data;
  const totalPages = response?.totalPages;
  const totalElements = response?.totalElements;

  if (isLoading) return <PageLoader />;

  return (
    <Row className="my-4">
      <>
        {mentorGroup?.length > 0 ? (
          mentorGroup?.map((group) => (
            <Col key={group.id} className="p-3 m-0" md={4}>
              <Card
                className="border-0 p-2 rounded-3"
                style={{ backgroundColor: "#FAFAFA" }}
              >
                <Card.Body>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {group.members.length > 0 ? (
                      group.members.map((member, index) => (
                        <Avatar
                          key={index}
                          name={`${member.firstName} ${member.lastName}`}
                          size="40"
                          round
                          style={{ marginLeft: index !== 0 ? "-15px" : 0 }}
                        />
                      ))
                    ) : (
                      <p>No members</p>
                    )}
                    {group.members.length > 5 && (
                      <div style={{ marginLeft: "-15px" }}>
                        <div
                          className="rounded-circle bg-white d-flex align-items-center justify-content-center"
                          style={{
                            width: "40px",
                            height: "40px",
                            fontSize: "14px",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          +{group.members.length - 5}
                        </div>
                      </div>
                    )}
                  </div>
                  <Row className="mt-5">
                    <Col>
                      <Card.Text
                        className="fw-bold mb-0"
                        style={{ fontSize: "16px" }}
                      >
                        {group.name}
                      </Card.Text>
                      <Card.Text
                        className="text-muted mb-0"
                        style={{ fontSize: "14px" }}
                      >
                        {group.members.length} members
                      </Card.Text>
                      <Link
                        className="text-decoration-none py-1"
                        to={`/e-mentoring/group-details/${group.id}`}
                      >
                        View More
                      </Link>
                    </Col>

                    <Col>
                      <LeaveGroup groupId={group.id} />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <p>No joined groups</p>
        )}
      </>
      <SharedPagination
        currentPage={currentPageIndex + 1}
        onPageChange={(newPage) => setCurrentPageIndex(newPage - 1)}
        totalPages={totalPages}
        pageSize={pageSize}
        totalElements={totalElements}
      />
    </Row>
  );
};

export default MyMentorshipGroups;
