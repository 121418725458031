import { Player } from "@lottiefiles/react-lottie-player";
import { RegisterAnim } from "../../../../../../presentation/assets";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const CompleteYourRegistrationToast = () => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <Player
            src={RegisterAnim}
            className="player"
            loop
            autoplay
            style={{ width: "150px", height: "150px" }}
          />
          <h5 style={{ fontSize: "16px" }}>Complete your registration</h5>
          <p style={{ fontSize: "14px" }}>
            Please fill the forms to complete your registration
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            onClick={handleClose}
            variant="dark"
            className="rounded-5"
            style={{ fontSize: "14px" }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CompleteYourRegistrationToast;
