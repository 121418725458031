import React, { ChangeEvent, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { DateRange } from "react-date-range";
import { CreateGroupEvent } from "../../../../../../models/request/mentoring-request";
import { useCreateGroupEventsMutation } from "../../../../../../services/mentoring-service";
import FileUpload from "../../../events/createEvent/components/FileUpload";

const CreateMentorshipEventsBody = ({ groupId }) => {
  const navigate = useNavigate();
  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const userId = parseInt(storedUserId);
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const [formData, setFormData] = useState<CreateGroupEvent>({
    createdBy: userId,
    title: "",
    description: "",
    link: "",
    image: "",
    groupId: groupId,
    startDay: "",
    endDay: "",
    startTime: "",
    endTime: "",
  });

  const handleFormChanged = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDateChange = (item) => {
    const startDate = item.selection.startDate.toISOString().split("T")[0];
    const endDate = item.selection.endDate
      ? item.selection.endDate.toISOString().split("T")[0]
      : null;
    setFormData((prevEventData) => ({
      ...prevEventData,
      startDay: startDate,
      endDay: endDate,
    }));
    setDate([item.selection]);

    console.log("Start Date:", startDate);
    console.log("End Date:", endDate);
  };

  const handleImageChanged = (imageInfo: {
    target: { name: string; value: string };
  }) => {
    console.log(
      "Image changed:",
      imageInfo.target.name,
      imageInfo.target.value
    );
    setFormData((prevEventData) => ({
      ...prevEventData,
      [imageInfo.target.name]: imageInfo.target.value,
    }));
  };

  const [createGroupEvent] = useCreateGroupEventsMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await createGroupEvent(formData);
      const { code, message } = response["data"];
      if (code === 0) {
        navigate(`/e-mentoring/group-details/${groupId}`);
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };

  return (
    <div>
      <Container>
        <Row className="p-4 align-items-center">
          <h5 className="">Group Event Details</h5>
          <hr className="hr" />
        </Row>
        <Row>
          <Form>
            <Row className="px-4">
              <Col lg={8}>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Event Title</Form.Label>
                      <Form.Control
                        size="lg"
                        type="text"
                        placeholder="Enter Event Title"
                        name="title"
                        onChange={handleFormChanged}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Event Link</Form.Label>
                      <Form.Control
                        size="lg"
                        type="text"
                        placeholder="Enter Group Link"
                        name="link"
                        onChange={handleFormChanged}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Group Description</Form.Label>
                  <Form.Control
                    onChange={handleFormChanged}
                    placeholder="Enter Group Description"
                    name="description"
                    as="textarea"
                    rows={3}
                  />
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Start Time</Form.Label>
                      <Form.Control
                        size="lg"
                        type="time"
                        placeholder="Enter Event Start Time"
                        name="startTime"
                        onChange={handleFormChanged}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>End Time</Form.Label>
                      <Form.Control
                        size="lg"
                        type="time"
                        placeholder="Enter Event End Time"
                        name="endTime"
                        onChange={handleFormChanged}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col lg={4}>
                <Row>
                  <Col className="bg-white ">
                    <p style={{ fontSize: "12px" }}>Start date - End date</p>
                    <DateRange
                      editableDateInputs={true}
                      onChange={handleDateChange}
                      moveRangeOnFirstSelection={false}
                      ranges={date}
                    />
                  </Col>
                </Row>
                <Row>
                  <FileUpload name="image" onChange={handleImageChanged} />
                </Row>
              </Col>
            </Row>

            <div className="d-flex w-100 justify-content-end">
              <Button
                className="rounded-5 w-25 py-3"
                variant="dark"
                style={{ fontSize: "14px" }}
                onClick={handleFormSubmit}
                disabled={
                  !formData.title ||
                  !formData.link ||
                  !formData.startTime ||
                  !formData.endTime ||
                  !formData.groupId ||
                  !formData.startDay ||
                  !formData.endDay
                }
              >
                Create Group Event
              </Button>
            </div>
          </Form>
        </Row>
      </Container>
    </div>
  );
};

export default CreateMentorshipEventsBody;
