import React, { ChangeEvent, useEffect, useState } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { GoArrowRight } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import toast from "react-hot-toast";
import { FiEye, FiEyeOff } from "react-icons/fi";
import Cookies from "js-cookie"; // Import Cookies
import { useLoginUserMutation } from "../../../services/auth-service";
import { LoginUserRequest } from "../../../models/request/login-user.request";

type FormControlChangeEvent = ChangeEvent<HTMLInputElement>;

const LoginPage: React.FC = () => {
  const navigate = useNavigate();

  const [loginUser, { isLoading: isLoginLoading }] = useLoginUserMutation();
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [formData, setFormData] = useState<LoginUserRequest>({
    id: "",
    secret: "",
  });

  // Load remembered credentials from cookies
  useEffect(() => {
    const savedCredentials = Cookies.get("rememberMeCredentials");
    if (savedCredentials && rememberMe) {
      setFormData(JSON.parse(savedCredentials));
    }
  }, [rememberMe]);

  const handleFormChanged = (e: FormControlChangeEvent) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await loginUser(formData);
      const { code, message, data } = response["data"];
      if (code === 0) {
        const {
          userId,
          firstName,
          lastName,
          access_token,
          registrationStatus,
          roles,
          user,
        } = data;
        if (rememberMe) {
          // Save credentials to cookies
          Cookies.set("rememberMeCredentials", JSON.stringify(formData), {
            expires: 30, // Cookie expiry in days
          });
        } else {
          // Remove remembered credentials cookie
          Cookies.remove("rememberMeCredentials");
        }
        sessionStorage.setItem("access_token", access_token);
        sessionStorage.setItem("userId", userId);
        sessionStorage.setItem("firstName", firstName);
        sessionStorage.setItem("lastName", lastName);
        sessionStorage.setItem("phoneNumber", user.username);
        sessionStorage.setItem("registrationStatus", registrationStatus);

        const userRole = roles.includes("Admin")
          ? "Admin"
          : roles.includes("Super Admin")
          ? "Super Admin"
          : roles.includes("Call Center Manager")
          ? "Call Center Manager"
           : roles.includes("Facilitator")
          ? "Facilitator"
          : roles.includes("Usher")
          ? "Usher"
          : roles.includes("Coordinator")
          ? "Coordinator"
          : roles.includes("Counsellor")
          ? "Counsellor"
          : roles.includes("Mentor")
          ? "Mentor"
          : "User";

        sessionStorage.setItem("roles", userRole);
        console.log("userRole", userRole);

        if (registrationStatus === "INCOMPLETE") {
          navigate(`/settings/account`);
        } else {
          navigate(`/dashboard`);
        }
      } else {
        toast.error(message);
      }
    } catch (error) {
      toast.error(error);
      console.error("Error:", error);
    }
  };

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ height: "90%" }}
    >
      <div className="w-100 px-lg-5">
        <div className="mt-3">
          <h2 className="fw-bolder mb-3">Sign in</h2>
          <p className="text-muted">Enter your phone number and password</p>
        </div>

        <Form onSubmit={handleFormSubmit}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control
              type="text"
              placeholder="Phone number"
              size="lg"
              name="id"
              value={formData.id}
              onChange={handleFormChanged}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <div className="d-flex align-items-center">
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                size="lg"
                name="secret"
                value={formData.secret}
                onChange={handleFormChanged}
              />
              {showPassword ? (
                <FiEyeOff
                  className="eye-icon"
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <FiEye
                  className="eye-icon"
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </div>
          </Form.Group>

          <Row className="mb-3">
            <Col>
              <Form.Check
                type="checkbox"
                label="Remember me"
                checked={rememberMe}
                onChange={handleRememberMeChange}
              />
            </Col>
            <Col className="text-end">
              <Link to="/auth/forget-password" className="text-decoration-none">
                Forgot password
              </Link>
            </Col>
          </Row>

          <Button
            variant="dark"
            type="submit"
            size="lg"
            className="rounded-5 w-100"
            disabled={isLoginLoading || !formData.id || !formData.secret}
          >
            {isLoginLoading ? (
              <Spinner
                animation="border"
                style={{ height: "20px", width: "20px" }}
              />
            ) : (
              <>
                <span>
                  Sign in <GoArrowRight className="mx-3" />
                </span>
              </>
            )}
          </Button>

          <p className="text-center mt-3">
            Registered with USSD?{" "}
            <Link to="/auth/request-otp" className="text-decoration-none ms-1">
              Continue
            </Link>
          </p>
          <p className="text-center p-0 m-0 mt-3">
            Don't have an account?{" "}
            <Link to="/auth/register" className="text-decoration-none ms-1">
              Create Account
            </Link>
          </p>
        </Form>
      </div>
    </Container>
  );
};

export default LoginPage;
