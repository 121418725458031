import React, { useEffect, useState } from "react";
import { RiFileUploadFill } from "react-icons/ri";
import toast from "react-hot-toast";
import { useBulkUploadMutation } from "../../../../../../services/user-service";

const BulkUpload = () => {
  const [file, setFile] = useState<File | null>(null);
  const [uploading, { isLoading }] = useBulkUploadMutation();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      // Check file extension
      const fileNameParts = selectedFile.name.split(".");
      const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
      if (fileExtension !== "xlsx") {
        toast.error("Please select a valid .xlsx file");
        event.target.value = ""; // Reset file input
      } else {
        setFile(selectedFile);
      }
    }
  };

  useEffect(() => {
    if (file) {
      handleFormSubmit();
    }
  }, [file]);

  const handleFormSubmit = async (e?) => {
    if (e) {
      e.preventDefault();
    }
    try {
      const formData = new FormData();
      formData.append("file", file!);
      const response = await uploading(formData);
      const { code, message } = response["data"];
      // Handle the response
      console.log("Upload successful", response);
      if (code === 200) {
        toast.success(message);
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      console.error("Upload failed:", error);
      toast.error(error.message);
    }
  };
  return (
    <div>
      <form onSubmit={handleFormSubmit}>
        <label
          htmlFor="fileInput"
          className="rounded-5 d-table mx-auto p-2 mx-1"
          style={{ fontSize: "12px",  background: "rgba(25, 22, 29, 0.06)", }}
        >
          Bulk upload <RiFileUploadFill className="my-auto ms-1" />
          <input
            id="fileInput"
            type="file"
            style={{ display: "none" }}
            name="file"
            onChange={handleFileChange}
          />
          {/* <button type="submit" disabled={isLoading}>
            {isLoading ? "Uploading..." : "Upload"}
          </button> */}
        </label>
      </form>
    </div>
  );
};

export default BulkUpload;
