import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { editPartnerType } from "../../../../../../models/request/create-event.request";
import { useUpdateEventPartnerMutation } from "../../../../../../services/event-service";

const EditPartnerModal = ({ partners }) => {
  const [show, setShow] = useState(false);

  const [partnerData, setPartnerData] = useState<editPartnerType>({
    id: partners.id,
    name: partners.name,
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setPartnerData((prevRegionData) => ({
      ...prevRegionData,
      [name]: value,
    }));
  };

  // Apollo hook for mutation
  const [updatePartnerMutation] = useUpdateEventPartnerMutation();

  // Function to handle form submission
  const handleUpdatePartner = async (e) => {
    e.preventDefault();
    try {
      // Make the mutation call
      const response = await updatePartnerMutation(partnerData);
      const { code, message } = response["data"];
      if (code === "00") {
        toast.success(message);
      } else {
        toast.error(message || "Something went wrong");
      }
      // Close the modal
      handleClose();
    } catch (error) {
      toast.error(error.message);
      console.error("Error creating region:", error);
    }
  };

  useEffect(() => {
    setPartnerData((prev) => ({
      ...prev,
      id: partners.id,
      name: partners.name,
    }));
  }, []);

  return (
    <>
      <Button
        className="py-2 rounded-5 mx-auto"
        variant="light"
        style={{ fontSize: "12px", background: "rgba(25, 22, 29, 0.06)" }}
        onClick={handleShow}
      >
        Edit partner
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Edit event partner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: "14px" }}>
                Event Partner
              </Form.Label>
              <Form.Control
                className="p-2"
                type="text"
                placeholder="Enter Partner Name"
                autoFocus
                name="name"
                value={partnerData.name}
                onChange={handleFormChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="rounded-5"
            variant="secondary"
            onClick={handleClose}
            style={{ fontSize: "14px" }}
          >
            Close
          </Button>
          <Button
            style={{ fontSize: "14px" }}
            className="rounded-5"
            variant="primary"
            onClick={handleUpdatePartner} // Call handleCreateRegion on button click
          >
            Update partner
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditPartnerModal;
