import UsersDataTable from "./components/UsersDataTable";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { IoSearchOutline } from "react-icons/io5";
import ProtectedRoutes from "../../../auth/utils/ProtectedRoutes";

const Users = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const [isSearchPerformed, setIsSearchPerformed] = useState(false);

  useEffect(() => {
    if (!tempSearchQuery && isSearchPerformed) {
      setSearchQuery("");
      setIsSearchPerformed(false);
    }
  }, [tempSearchQuery, isSearchPerformed]);

  const handleSearch = () => {
    setSearchQuery(tempSearchQuery);
    setIsSearchPerformed(true);
  };

  return (
    <Container fluid>
      <Row className="p-3 align-items-center">
        <Col>
          <h1 className="text-black fs-4 py-2">Users</h1>
        </Col>
        <Col md={2} className="mx-auto m-0 p-0 position-relative">
          <span className="position-absolute ps-2 pt-2 py-auto">
            <IoSearchOutline />
          </span>
          <Form.Control
            type="search"
            className="py-2 border border-1 rounded-5"
            style={{ paddingLeft: "1.8rem" }}
            placeholder="Search"
            value={tempSearchQuery} // Use tempSearchQuery
            onChange={(e) => setTempSearchQuery(e.target.value)}
          />
        </Col>
        <Col md={2}>
          <Button variant="dark" className="rounded-5" onClick={handleSearch}>
            Search
          </Button>{" "}
          {/* Search button */}
        </Col>
      </Row>
      <Row>
        <Col>
          <UsersDataTable searchQuery={searchQuery} />
        </Col>
      </Row>
    </Container>
  );
};

export default ProtectedRoutes(Users, {
  allowedRoles: ["Super Admin", "Admin", "Coordinator", "Call Center Manager"],
});
