import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
  ListGroup,
  Button,
  Modal,
} from "react-bootstrap";
import { useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import RescheduleCounselling from "./RescheduleCounselling";
import Feedback from "./Feedback";
import { useGetCounsellingByUserIDQuery } from "../../../../../../services/counselling-service";
import PageLoader from "../../../../../components/PageLoader";

const localizer = momentLocalizer(moment);

const MyCalendar = () => {
  const navigate = useNavigate();
  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const userId = parseInt(storedUserId);
  const [selectedEvent, setSelectedEvent] = useState(null); // Track selected event for modal
  const { data: response, isLoading } = useGetCounsellingByUserIDQuery(userId);

  // Check if data is loading
  if (isLoading) {
    return (
      <div>
        <PageLoader />
      </div>
    );
  }

  // Transform data to events array
  const events =
    response?.data.map((item) => ({
      id: item.id,
      title: item.requestType,
      start: new Date(item.preferredStartAt),
      end: new Date(item.preferredEndAt),
      cancelled: item.cancelled,
      confirm: item.confirmed,
      counsellor: item.confirmation?.counsellors,
      startAt: item.confirmation?.startAt,
    })) || [];

  // Event click handler to open modal
  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };

  // Tooltip for cancelled event
  const CancelledTooltip = ({ event }) => (
    <Tooltip id={`tooltip-${event.id}`}>Counsell cancelled</Tooltip>
  );

  return (
    <Row className="bg-white rounded-3 mx-2">
      <Col className="p-4 ">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 550 }}
          onSelectEvent={handleEventClick} // Event click handler
          eventPropGetter={(event) => ({
            style: event.cancelled ? { backgroundColor: "lightgray" } : {},
          })}
        />
        {/* Render tooltip for cancelled event */}

        {selectedEvent && selectedEvent.cancelled && (
          <OverlayTrigger
            placement="top"
            overlay={<CancelledTooltip event={selectedEvent} />}
          >
            <div style={{ display: "none" }}>
              {" "}
              {/* Wrap children inside a single container */}
              <div>Child 1</div>
              <div>Child 2</div>
            </div>
          </OverlayTrigger>
        )}

        {/* Modal for event details */}
        {!selectedEvent?.cancelled && ( // Render modal only if event is not cancelled
          <Modal
            id="exampleModalToggle"
            show={!!selectedEvent}
            onHide={() => setSelectedEvent(null)}
            size="sm"
            centered
          >
            <Modal.Header className="border-0 pb-0" closeButton>
              <Modal.Title className="fs-6">{selectedEvent?.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ListGroup>
                <ListGroup.Item
                  onClick={() =>
                    navigate("/e-counselling/request/cancel", {
                      state: { selectedEvent },
                    })
                  }
                >
                  <Button className="border-0 bg-transparent p-0 text-black">
                    <MdOutlineCancel /> Cancel
                  </Button>
                </ListGroup.Item>
                {selectedEvent?.confirm === true && (
                  <>
                    <ListGroup.Item>
                      <RescheduleCounselling counsellData={selectedEvent} />
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Feedback counsellData={selectedEvent} />
                    </ListGroup.Item>
                  </>
                )}
              </ListGroup>
            </Modal.Body>
          </Modal>
        )}
      </Col>
    </Row>
  );
};

export default MyCalendar;
