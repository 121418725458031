import { ChangeEvent, useState } from "react";
import toast from "react-hot-toast";
import { Button, Form, Modal } from "react-bootstrap";
import { createGroupType } from "../../../../../../models/request/counselling-request";
import { useCreateCounsellingTypeMutation } from "../../../../../../services/counselling-service";

const AddCounsellingType = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [counsellType, setCounsellType] = useState<createGroupType>({
    name: "",
    description: "",
  });

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCounsellType((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [createCounsell] = useCreateCounsellingTypeMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await createCounsell(counsellType);
      const { code, message } = response["data"];
      if (code === 0) {
        setCounsellType({
          name: "",
          description: "",
        });
        toast.success(message);
        handleClose();
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };

  return (
    <>
      <Button
        style={{ fontSize: "14px" }}
        variant="dark"
        className="rounded-5"
        onClick={handleShow}
      >
        Create Counselling Type +
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">Create Counselling Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                placeholder="Enter Name"
                name="name"
                onChange={handleFormChanged}
                value={counsellType.name}
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                onChange={handleFormChanged}
                name="description"
                as="textarea"
                value={counsellType.description}
                rows={3}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="rounded-5"
            variant="primary"
            onClick={handleFormSubmit}
            disabled={!counsellType.name}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddCounsellingType;
