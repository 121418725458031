import { ChangeEvent, useState } from "react";
import toast from "react-hot-toast";
import { Button, Form, Modal } from "react-bootstrap";
import { createAnnouncementType } from "../../../../../../../models/request/mentoring-request";
import { useCreateAnnouncementRequestMutation } from "../../../../../../../services/mentoring-service";

const CreateGroupAnnouncement = ({ groupID }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [firstName] = useState(sessionStorage.getItem("firstName"));
  const [lastName] = useState(sessionStorage.getItem("lastName"));
  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const userId = parseInt(storedUserId);

  const [formData, setFormData] = useState<createAnnouncementType>({
    groupId: groupID,
    subject: "",
    mentorId: userId,
    announcerName: `${firstName} ${lastName}`,
  });

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [createAnnouncement] = useCreateAnnouncementRequestMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await createAnnouncement(formData);
      const { code, message } = response["data"];
      if (code === 0) {
        setFormData({
          groupId: groupID,
          subject: "",
          mentorId: userId,
          announcerName: `${firstName} ${lastName}`,
        });
        toast.success(message);
        handleClose();
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };

  return (
    <>
      <Button
        style={{ fontSize: "14px" }}
        variant="dark"
        className="rounded-5"
        onClick={handleShow}
      >
        Create Announcement +
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">Create Announcement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label> Description</Form.Label>
              <Form.Control
                onChange={handleFormChanged}
                name="subject"
                as="textarea"
                value={formData.subject}
                rows={3}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button
            className="rounded-5"
            variant="primary"
            onClick={handleFormSubmit}
            disabled={!formData.subject}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateGroupAnnouncement;
