import { Rating } from "react-simple-star-rating";
import { ChangeEvent, useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { IoArrowUndoOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { MdOutlineChat } from "react-icons/md";
import { sendFeedbackRequest } from "../../../../../../models/request/counselling-request";
import { useSendFeedbackMutation } from "../../../../../../services/counselling-service";

const Feedback = ({ counsellData }) => {
  const navigate = useNavigate();
  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const userId = parseInt(storedUserId);
  const [show, setShow] = useState(false);
  const [rating, setRating] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [firstName] = useState(sessionStorage.getItem("firstName"));
  const [lastName] = useState(sessionStorage.getItem("lastName"));
  const [phoneNumber] = useState(sessionStorage.getItem("phoneNumber"));
  console.log("counsellData", counsellData);
  const [feedbackData, setFeedbackData] = useState<sendFeedbackRequest>({
    requestId: counsellData?.id,
    comments: "",
    rating: 0,
    userId: userId,
    firstName: firstName,
    lastName: lastName,
    userPhone: phoneNumber,
  });

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFeedbackData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleRating = (rate) => {
    // Ensure the rating is from 1 to 5
    if (rate >= 1 && rate <= 5) {
      setRating(rate);
      setFeedbackData((prevFormData) => ({
        ...prevFormData,
        rating: rate, // Set the rating in feedbackData
      }));
    }
  };

  const [sendFeedback] = useSendFeedbackMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await sendFeedback(feedbackData);
      const { code, message, data } = response["data"];
      if (code === 0) {
        toast.success(message);
        navigate("/e-counselling/request/feedback", {
          state: { counsellData },
        });
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };

  return (
    <>
      <Button
        className="border-0 bg-transparent p-0 text-black "
        onClick={handleShow}
      >
        <MdOutlineChat /> Send Feedback
      </Button>

      <Modal className="border-0" show={show} onHide={handleClose}>
        <Modal.Header className="border-0 mx-4 my-2" closeButton>
          <Modal.Title className="fs-6">
            <img
              src={`https://ui-avatars.com/api/?name=${firstName}+${lastName}&background=random&size=32`}
              className="rounded-circle me-2"
              alt="avatar"
              style={{ width: "40px", height: "40px" }}
            />
            <span className="my-auto">
              {firstName} {lastName}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Row className="mx-4">
          <p className="py-1 ">
            Give your feedback on your {counsellData?.title} counselling session
            which happened on{" "}
            {new Date(counsellData?.startAt).toLocaleDateString()} with{" "}
            {counsellData?.counsellor?.map((counsell: any) => (
              <div key={counsell.id}>
                {counsell.firstName} {counsell.lastName}
              </div>
            ))}
            .
          </p>

          {/* <p className="  fw-light fst-italic text-end" style={{fontSize: "12px"}}>replied by {fullName}</p> */}
        </Row>

        <>
          <Modal.Body className="mx-4 my-0 py-0">
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  placeholder="Enter reply"
                  name="comments"
                  onChange={handleFormChanged}
                  as="textarea"
                  rows={3}

                  // disabled={enquiryData.enquiryClosed === true}
                />
              </Form.Group>
              <Rating onClick={handleRating} initialValue={rating} />
            </Form>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Button
              onClick={handleFormSubmit}
              variant="dark"
              disabled={!feedbackData?.comments}
              className="rounded-5"
              style={{ fontSize: "14px" }}
            >
              Reply <IoArrowUndoOutline />
            </Button>
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};

export default Feedback;
