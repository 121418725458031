import { GridColDef } from "@mui/x-data-grid";
import { Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import CounsellorAvailability from "./CounsellorAvailability";
import DeleteCounsellor from "./DeleteCounsellor";

const CounsellorTableElements: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    width: 100,
    headerClassName: "header--bg",
  },

  {
    field: "fullName",
    headerName: "Full Name",
    headerClassName: "header--bg",
    flex: 1,
    renderCell: (params: any) => (
      <>
        <Image
          src={`https://ui-avatars.com/api/?name=${params?.row?.user?.firstName}+${params?.row?.user.lastName}&background=random&size=32`}
          alt="Avatar"
          roundedCircle
          className="me-2"
        />
        {params?.row?.user?.firstName || ""} {params?.row?.user?.lastName || ""}
      </>
    ),
  },

  {
    field: "available",
    headerName: "Availability",
    flex: 1,
    headerClassName: "header--bg",
    renderCell: (params) => {
      console.log("params", params);
      return (
        <div>
          <CounsellorAvailability
            available={params?.row?.available}
            counsellorID={params?.row?.id}
          />
        </div>
      );
    },
  },

  {
    field: "createdOn",
    headerName: "Date Created",
    headerClassName: "header--bg",
    flex: 1,
    valueFormatter: (params: any) =>
      new Date(params.value).toLocaleDateString(),
  },

  {
    field: "actions",
    headerName: "Actions",
    headerClassName: "header--bg",
    width: 300,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      console.log("params", params);
      return (
        <div>
          {/* <AddSlot mentorID={params?.row?.id} /> */}
          <Link to={`/e-counselling/view-profile/${params?.row?.user.userId}`}>
            <Button
              variant="light"
              className="rounded-5 "
              style={{ fontSize: "12px", background: "rgba(25, 22, 29, 0.06)" }}
            >
              View profile
            </Button>
          </Link>
          <DeleteCounsellor userId={params?.row?.user?.userId} />
        </div>
      );
    },
  },
];

export default CounsellorTableElements;
