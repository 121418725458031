import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Col, Row } from "react-bootstrap";

const localizer = momentLocalizer(moment);

const myEventsList = [
  {
    title: "Meeting with John",
    start: new Date(2023, 6, 10, 10, 0), // July 10, 2023, 10:00 AM
    end: new Date(2023, 6, 10, 11, 0), // July 10, 2023, 11:00 AM
  },
  {
    title: "Project Deadline",
    start: new Date(2023, 6, 15, 0, 0), // July 15, 2023, all day
    end: new Date(2023, 6, 15, 23, 59), // July 15, 2023, all day
  },
  {
    title: "Team Lunch",
    start: new Date(2023, 6, 20, 12, 0), // July 20, 2023, 12:00 PM
    end: new Date(2023, 6, 20, 13, 0), // July 20, 2023, 1:00 PM
  },
];

export const CounsellorRequest = () => (
  <Row className="bg-white rounded-3 mx-2">
    <Col className="p-4 ">
      <Calendar
        localizer={localizer}
        events={myEventsList}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
      />
    </Col>
  </Row>
);


