import { Row, Col, Container } from "react-bootstrap";
import Sidebar from "../components/Sidebar";
import { Outlet } from "react-router-dom";
import MobileHeader from "../components/MobileHeader";

const MainLayout = () => {
  return (
    <Container fluid className="main-bg ">
      <Row className="rounded-4 position-fixed z-3 bg-white m-0 p-0 d-lg-none d-xs-block">
        <MobileHeader />
      </Row>
      <Row className="p-1" style={{ minHeight: "100vh" }}>
        <Col
          md={2}
          className="rounded-4 bg-white-80 me-2 d-none d-lg-block"
          style={{ maxHeight: "100%" }}
        >
          <Sidebar />
        </Col>
        <Col
          className="rounded-4 bg-white-80 overflow-y-scroll m-0 p-0"
          style={{ maxHeight: "100%" }}
        >
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};

export default MainLayout;
