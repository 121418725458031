import ProtectedRoutes from "../../../auth/utils/ProtectedRoutes";
import CreateEventDetails from "./components/CreateEventDetails";
import CreateEventHeader from "./components/CreateEventHeader";

const CreateEvent = () => {
  return (
    <div className="overflow-x-hidden">
      <CreateEventHeader />
      <CreateEventDetails />
    </div>
  );
};

export default ProtectedRoutes(CreateEvent, {
  allowedRoles: ["Super Admin", "Admin", "Call Center Manager"],
});
