import { ChangeEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Button, Form, Modal } from "react-bootstrap";
import { editCounsellingTypeParams } from "../../../../../../models/request/counselling-request";
import { useEditCounsellingTypeMutation } from "../../../../../../services/counselling-service";

const EditCounsellingType = ({ counsellingType }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState<editCounsellingTypeParams>({
    id: counsellingType.id,
    name: counsellingType.name,
    description: counsellingType.description,
  });

  const handleFormChanged = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [editCounsellingType] = useEditCounsellingTypeMutation();

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await editCounsellingType(formData);
      console.log("editCounsellingType", response);
      const { code, message } = response["data"];
      if (code === 0) {
        toast.success(message);
        handleClose();
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      console.error("Error:", error);
      toast.error(error.message || "An unexpected error occurred");
    }
  };

  useEffect(() => {
    setFormData({
      id: counsellingType.id,
      name: counsellingType.name,
      description: counsellingType.description,
    });
  }, [counsellingType]);

  return (
    <>
      <Button
        style={{ fontSize: "12px", background: "rgba(25, 22, 29, 0.06)" }}
        className="rounded-5 text-black border-0"
        onClick={handleShow}
      >
        Edit Counselling Type
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">Edit Counselling Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                placeholder="Enter Name"
                name="name"
                value={formData.name}
                onChange={handleFormChanged}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                name="description"
                as="textarea"
                rows={3}
                value={formData.description}
                onChange={handleFormChanged}
              />
            </Form.Group>
            <Modal.Footer>
              <Button className="rounded-5" variant="primary" type="submit">
                Update
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditCounsellingType;
