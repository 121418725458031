import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FormRequest } from "../models/request/form";
import { AppConstants } from "../core/mocks/app-constants";
import { BaseResponse } from "../models/response/base-response";
import { FormResponse } from "../models/response/form-response";
import { Pagination } from "../models/pagination";
import {
  Answer2,
  FormAnswerRequest,
  FormAnswerResponse,
} from "../models/response/FormAnswerDTO";

export const formAnswerService = createApi({
  reducerPath: "formAnswerService",
  baseQuery: fetchBaseQuery({
    baseUrl: `${AppConstants.formBaseUrl}`,
  }),
  tagTypes: ["FormAnswers"],
  endpoints: (build) => ({
    createFormAnswer: build.mutation<BaseResponse<any>, FormAnswerRequest>({
      query: (request: FormAnswerRequest) => ({
        url: `/form/answer`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["FormAnswers"],
    }),

    getFormAnswer: build.query<BaseResponse<FormResponse>, number>({
      query: (id) => ({
        url: `/form/answer/${id}`,
        method: "GET",
      }),
      providesTags: ["FormAnswers"],
    }),
    getFormAnswers: build.query<BaseResponse<FormAnswerResponse[]>, Pagination>(
      {
        query: (pagination: Pagination) => ({
          url: `/form/answer/?page=${pagination.page}&size=${pagination.size}`,
          method: "GET",
        }),
        providesTags: ["FormAnswers"],
      }
    ),
    updateFormAnswer: build.mutation<BaseResponse<FormResponse>, FormRequest>({
      query: (id) => ({
        url: `/form/answer`,
        method: "GET",
      }),
      invalidatesTags: ["FormAnswers"],
    }),
    getUserLocation: build.query<BaseResponse<FormResponse>, number>({
      query: (id) => ({
        url: `/form/answer/user-location?formId=13&userId=${id}`,
        method: "GET",
      }),
      providesTags: ["FormAnswers"],
    }),
    updateUserLocation: build.mutation<BaseResponse<FormResponse>, Answer2>({
      query: (request: Answer2) => ({
        url: "/form/question/answer/multiple",
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["FormAnswers"],
    }),
  }),
});

export const {
  useCreateFormAnswerMutation,
  useGetFormAnswerQuery,
  useUpdateFormAnswerMutation,
  useGetUserLocationQuery,
  useUpdateUserLocationMutation
} = formAnswerService;
