import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import { IoIosArrowDown } from "react-icons/io";
import { assignMentorGroupType } from "../../../../../../models/request/mentoring-request";
import {
  useAssignMentorGroupMutation,
  useGetMentorListQuery,
} from "../../../../../../services/mentoring-service";

const AssignMentorToGroup = ({ groupId }) => {
  const [show, setShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [assignData, setAssignData] = useState<assignMentorGroupType>({
    groupId: groupId,
    mentors: [0],
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [assignMentor] = useAssignMentorGroupMutation();

  const { data: Response, isLoading } = useGetMentorListQuery({
    page_index: 0,
    page_size: 99999999,
  });

  const mentor = Response?.data;

  const mentorOptions = mentor?.map((ment) => ({
    value: ment.id,
    label: `${ment.user.firstName} ${ment.user.lastName}`,
  }));

  const handleUserSelectChange = (selectedOption) => {
    setSelectedUser(selectedOption);
    // Assuming 'assignData' is supposed to store the selected counsellors
    setAssignData({
      ...assignData,
      mentors: selectedOption.map((option) => option.value),
    });
  };

  const handleCreateCounsellor = async (e) => {
    e.preventDefault();
    try {
      const response = await assignMentor(assignData);
      const { code, message } = response["data"];
      if (code === 0) {
        toast.success(message);
      } else {
        toast.error(message || "Something went wrong");
      }
      handleClose();
    } catch (error) {
      toast.error(error.message);
      console.error("Error creating counsellor:", error);
    }
  };

  return (
    <>
      <div
        className="py-1"
        style={{ fontSize: "14px", cursor: "pointer" }}
        onClick={handleShow}
      >
        Assign Mentor <IoIosArrowDown />
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Assign Mentor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: "14px" }}>Mentors</Form.Label>
              <Select
                className="p-2"
                options={mentorOptions}
                onChange={handleUserSelectChange}
                value={selectedUser}
                isLoading={isLoading}
                isDisabled={isLoading}
                isMulti
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="rounded-5"
            variant="secondary"
            onClick={handleClose}
            style={{ fontSize: "14px" }}
          >
            Close
          </Button>
          <Button
            style={{ fontSize: "14px" }}
            className="rounded-5"
            variant="primary"
            onClick={handleCreateCounsellor}
            disabled={!selectedUser}
          >
            Assign
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssignMentorToGroup;
