import React, { ChangeEvent, useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { GoArrowRight } from "react-icons/go";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { OtpPhoneRequest } from "../../../models/request/login-user.request";
import { useGenerateOTPMutation } from "../../../services/user-service";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<OtpPhoneRequest>({
    phone: "",
  });
  const [isOTPLoading, setIsOTPLoading] = useState(false);
  const [generateOTP] = useGenerateOTPMutation();

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsOTPLoading(true);

    try {
      const response = await generateOTP(formData);
      const { code, message } = response["data"];
      if (code === 200) {
        toast.success("You will receive an OTP to reset your password");
        navigate("/auth/verify-otp-password", { state: { phone: formData.phone } });
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    } finally {
      setIsOTPLoading(false);
    }
  };

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ height: "90%" }}
    >
      <div className="w-100 px-lg-5">
        <div className="mb-5">
          <h2 className="fw-bolder">Forget Password</h2>
        </div>

        <Form onSubmit={handleFormSubmit}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control
              type="text"
              placeholder="Enter Phone Number"
              size="lg"
              name="phone"
              onChange={handleFormChanged}
              required
            />
          </Form.Group>

          <div className="mt-3">
            <Button variant="dark" type="submit" className="rounded-5 w-100">
              {isOTPLoading ? "Requesting..." : "Reset Password"}
              <GoArrowRight className="mx-3" />
            </Button>
          </div>
        </Form>
      </div>
    </Container>
  );
};

export default ForgetPassword;
