import SwiperComponent from "../../../../events/eventMain/components/SwiperComponent";
import { Card, Col, Row, Image, Button } from "react-bootstrap";
import { useGetGroupEventsQuery } from "../../../../../../../services/mentoring-service";
import { CalenderIcon, Clock, GameConsole } from "../../../../../../assets";
import { CiVideoOn } from "react-icons/ci";
import PageLoader from "../../../../../../components/PageLoader";

const GroupEvents = ({ groupID }) => {
  const { data: response, isLoading } = useGetGroupEventsQuery({
    page_index: 0,
    page_size: 99999999,
    groupId: groupID,
  });

  const groupEvents = response?.data;

  if (!groupEvents || groupEvents.length === 0) {
    return <h5>No Event groups</h5>;
  }

  if (isLoading) {
    return <PageLoader />; // Show loading spinner
  }

  return (
    <Col className="pt-4">
      <SwiperComponent>
        {groupEvents?.map((group) => (
          <Card
            className="bg-white rounded-4 object-fit-cover d-flex flex-column"
            key={group.id}
            style={{ height: "250px", maxWidth: "468px" }}
          >
            <Row className="g-0 h-100">
              {group.image && (
                <Col className=" rounded-start-4">
                  <Image
                    alt="Event Image"
                    src={group.image}
                    className="img-fluid w-100 h-100 object-fit-cover rounded-start-4"
                  />
                </Col>
              )}
              <Col>
                <Card.Body style={{ maxWidth: "260px" }}>
                  <div className="d-flex justify-content-between">
                    <Card.Title className="event-card-title">
                      {group.title?.length > 22
                        ? `${group.title.substring(0, 22)}...`
                        : group.title}
                    </Card.Title>
                  </div>

                  <ul className="list-unstyled d-flex flex-column event-card-text my-1">
                    <li className="icon-link text-decoration-underline">
                      <a
                        href={group.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CiVideoOn className="fs-6 me-2 " />
                        {group.link?.length > 22
                          ? `${group.link.substring(0, 22)}...`
                          : group.link}
                      </a>
                    </li>

                    <li className="icon-link">
                      <Image src={Clock} alt="" width={20} />
                      {group.startTime} - {group.endTime}
                    </li>

                    <li className="icon-link text-nowrap">
                      <Image src={CalenderIcon} alt="" width={20} />
                      {group.startDay} - {group.endDay}
                    </li>
                  </ul>

                  <div className="d-flex align-items-center">
                    <Image
                      alt=""
                      className="rounded-circle p-1 bg-light"
                      src={GameConsole}
                      width={24}
                    />
                    <hr className="hr w-100"></hr>
                  </div>

                  <Card.Text className="event-card-text text-small text-muted">
                    {group.description?.length > 50
                      ? `${group.description.substring(0, 50)}...`
                      : group.description || "No description"}
                  </Card.Text>

                  {/* <Row>
                    <Col>
                      <Button
                        variant="dark rounded-5"
                        style={{ fontSize: "12px", fontWeight: "400" }}
                      >
                        Register +
                      </Button>
                    </Col>
                  </Row> */}
                </Card.Body>
              </Col>
            </Row>
          </Card>
        ))}
      </SwiperComponent>
    </Col>
  );
};

export default GroupEvents;
