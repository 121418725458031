import React from "react";
import { useNavigate } from "react-router-dom";
import { useApproveRequestMutation } from "../../../../../../../services/mentoring-service";
import toast from "react-hot-toast";
import { Button } from "react-bootstrap";

const ApproveAllRequest = ({ requestIds }) => {
  const navigate = useNavigate();

  const [approval] = useApproveRequestMutation();

  const handleFormSubmit = async () => {
    try {
      const response = await approval({ requestIds });
      const { code, message } = response["data"];
      if (code === 0) {
        toast.success(message);
        navigate("/e-mentoring/approval");
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };

  return (
    <Button
      onClick={handleFormSubmit}
      style={{ fontSize: "12px" }}
      variant="dark"
      className="rounded-5 mx-1 mb-2"
    >
      Approve All
    </Button>
  );
};

export default ApproveAllRequest;
