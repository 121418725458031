import { Container, Row, Col, InputGroup, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { IoIosArrowRoundForward } from "react-icons/io";
import { FaLink } from "react-icons/fa6";
import { GrLocation } from "react-icons/gr";
import { ChangeEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import FileUpload from "./FileUpload";
import DateCalender from "./DateCalender";
import Collaborators from "./Collaborators";
import Host from "./Host";
import MediaHouse from "./MediaHouse";
import { useNavigate, useParams } from "react-router-dom";
import RegionDistrict from "./RegionDistrict";
import Facilitators from "./Facilators";
import { useForm } from "react-hook-form";
import {
  useEditEventMutation,
  useGetEventByIdQuery,
} from "../../../../../../services/event-service";
import { EditEvent } from "../../../../../../models/request/create-event.request";
import PageLoader from "../../../../../components/PageLoader";
import Interventions from "./Interventions";

type FormControlChangeEvent = ChangeEvent<HTMLInputElement>;

const EditEventDetails = () => {
  const { id } = useParams();
  const eventId = Number(id);

  const { data: response, isLoading } = useGetEventByIdQuery(eventId);
  const eventData = response?.data?.[0];

  const {
    register,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const userId = parseInt(storedUserId);

  const [eventEditData, setEventEditData] = useState<EditEvent>({
    id: eventId,
    title: eventData?.title || "",
    category: eventData?.category || "",
    active: eventData?.active || false,
    status: eventData?.status || "UPCOMING",
    location: {
      id: eventData?.location?.id,
      city: eventData?.location?.city || "",
      regionId: eventData?.location?.district?.region.id || "",
      districtId: eventData?.location?.district?.id || "",
      nameOfVenue: eventData?.location?.nameOfVenue || "",
      latitude: eventData?.location?.latitude || "",
      longitude: eventData?.location?.longitude || "",
    },
    participants:
      eventData?.participants?.map((participant) => participant.id) || [],
    mediaHouses:
      eventData?.mediaHouses?.map((mediaHouse) => mediaHouse.id) || [],
    collaborators:
      eventData?.collaborators?.map((collaborator) => collaborator.id) || [],
    facilitators: Array.isArray(eventData?.facilitators)
      ? eventData.facilitators
      : [],
    host: eventData?.host?.id || 0,
    stime: eventData?.stime || "",
    etime: eventData?.etime || "",
    intervention: eventData?.intervention?.id || 0,
    startDay: eventData?.startDay || "",
    endDay: eventData?.endDay || "",
    image: eventData?.image || "",
    link: eventData?.link || "",
    description: eventData?.description || "",
    modifiedBy: userId,
    public: eventData?.active || false,
  });

  console.log("event data to be sent:: ", eventEditData);

  const handleFormChanged = (e: FormControlChangeEvent) => {
    const { name, value } = e.target;

    // Check if the field is part of the location object
    if (name.startsWith("location.")) {
      const locationField = name.split(".")[1];
      setEventEditData((prevEventData) => ({
        ...prevEventData,
        location: {
          ...prevEventData.location,
          [locationField]: value,
        },
      }));
    } else {
      setEventEditData((prevEventData) => ({
        ...prevEventData,
        [name]: value,
      }));
    }
  };

  const handleFormSelectChanged: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    console.log("name == ", e);
    const { name, value } = e.target;

    // Check if the field is part of the location object
    if (name.startsWith("location.")) {
      const locationField = name.split(".")[1];
      setEventEditData((prevEventData) => ({
        ...prevEventData,
        location: {
          ...prevEventData.location,
          [locationField]: value,
        },
      }));
    } else {
      setEventEditData((prevEventData) => ({
        ...prevEventData,
        [name]: value,
      }));
    }

    console.log("form data for edit :: ", eventEditData);
  };

  const handleFacilitatorSelectChange = (selectedOptions) => {
    setEventEditData((prevEventData) => ({
      ...prevEventData,
      facilitators: selectedOptions.map((option) => option.value),
    }));
  };

  const handleCollaboratorsSelectChange = (selectedOptions) => {
    setEventEditData((prevEventData) => ({
      ...prevEventData,
      collaborators: selectedOptions.map((option) => option.value),
    }));
  };

  const handleMediaSelectChange = (selectedOptions) => {
    setEventEditData((prevEventData) => ({
      ...prevEventData,
      mediaHouses: selectedOptions.map((option) => option.value),
    }));
  };

  const handleImageChanged = (imageInfo: {
    target: { name: string; value: string };
  }) => {
    console.log(
      "Image changed:",
      imageInfo.target.name,
      imageInfo.target.value
    );
    setEventEditData((prevEventData) => ({
      ...prevEventData,
      [imageInfo.target.name]: imageInfo.target.value,
    }));
  };

  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const handleDateChange = (item) => {
    const startDate = item.selection.startDate.toISOString().split("T")[0];
    const endDate = item.selection.endDate
      ? item.selection.endDate.toISOString().split("T")[0]
      : null;
    setEventEditData((prevEventData) => ({
      ...prevEventData,
      startDay: startDate,
      endDay: endDate,
    }));
    setDate([item.selection]);

    console.log("Start Date:", startDate);
    console.log("End Date:", endDate);
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    // For status field, directly set the value
    if (name === "status") {
      setEventEditData((prevEventData) => ({
        ...prevEventData,
        [name]: checked ? "true" : "false",
      }));
    }
  };

  const [editEvent] = useEditEventMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    let request: EditEvent;

    if (eventEditData.category !== "VIRTUAL_SESSION") {
      request = { ...eventEditData };
    } else {
      request = {
        ...eventEditData,
        location: null,
      };
    }

    try {
      const response = await editEvent(request);
      const { code, message } = response["data"];
      console.log("response", response);
      if (code === "00") {
        toast.success("Event Updated successfully");
        navigate("/events");
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const formatTime = (time: string): string => {
      const [hour, minute] = time.split(":");
      return `${hour}:${minute}`;
    };
    setEventEditData((prev) => ({
      ...prev,
      title: eventData?.title,
      category: eventData?.category,
      active: eventData?.active,
      status: eventData?.status,
      location: {
        id: eventData?.location?.id,
        city: eventData?.location?.city,
        districtId: eventData?.location?.district?.id,
        regionId: eventData?.location?.district?.region.id,
        nameOfVenue: eventData?.location?.nameOfVenue,
        latitude: eventData?.location?.latitude,
        longitude: eventData?.location?.longitude,
      },
      participants: eventData?.participants?.map(
        (participant) => participant.id
      ),
      mediaHouses: eventData?.mediaHouses?.map((mediaHouse) => mediaHouse.id),
      collaborators: eventData?.collaborators?.map(
        (collaborator) => collaborator.id
      ),
      facilitators: Array.isArray(eventData?.facilitators)
        ? eventData.facilitators
        : [],
      host: eventData?.host?.id,
      stime: eventData?.stime ? formatTime(eventData.stime) : "",
      etime: eventData?.etime ? formatTime(eventData.etime) : "",
      startDay: eventData?.startDay,
      endDay: eventData?.endDay,
      image: eventData?.image,
      link: eventData?.link,
      description: eventData?.description,
      public: eventData?.active,
      intervention: eventData?.intervention?.id,
    }));
  }, [eventData]);

  if (isLoading) {
    return (
      <div>
        <PageLoader />
      </div>
    );
  }
  return (
    <Container>
      <Row className="px-3">
        <Col lg={8}>
          <Row>
            <div className="d-flex align-items-center">
              <h5 className="py-3 m-0 event-details-title">Event Details</h5>
              <hr className="hr w-75"></hr>
            </div>
            <Row className="m-0 p-0">
              <Col className="m-0 " lg={6}>
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <div className="d-flex align-items-center">
                    <Form.Label className="event-label">Event Name</Form.Label>
                  </div>
                  <Form.Control
                    className="event-details-form"
                    type="text"
                    name="title"
                    value={eventEditData.title}
                    onChange={handleFormChanged}
                    {...register("title", {
                      required: "Event Name is required",
                    })}
                  />
                  {errors.title && (
                    <Form.Text className="text-danger">
                      {String(errors.title.message)}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col className="m-0 " lg={6}>
                <div className="d-flex align-items-center">
                  <Form.Label className="event-label">Event type</Form.Label>
                </div>
                <Form.Select
                  className="event-details-form"
                  aria-label="Default select example"
                  name="category"
                  {...register("category", {
                    required: "Event Category is required",
                  })}
                  value={eventEditData.category}
                  onChange={handleFormSelectChanged}
                  disabled={eventData?.category === "VIRTUAL_SESSION"}
                >
                  <option className="text-muted" value="" selected disabled>
                    Select event type
                  </option>
                  <option value="IN_PERSON_SESSION">In-person session</option>
                  <option value="IN_PERSON_SESSION_MENTORSHIP">
                    In-person session mentorship
                  </option>
                  <option value="IN_PERSON_SESSION_COUNSELLING">
                    In-person session counselling
                  </option>
                  <option value="MEDIA_ENGAGEMENT">Media engagement</option>
                  <option value="VIRTUAL_SESSION">Virtual session</option>
                </Form.Select>
                {errors.category && (
                  <Form.Text className="text-danger">
                    {String(errors.category.message)}
                  </Form.Text>
                )}
              </Col>
            </Row>
            {eventEditData?.category === "MEDIA_ENGAGEMENT" && (
              <Row>
                <Col>
                  <div className="d-flex align-items-center">
                    <h5 className=" event-label">Media engagement</h5>
                  </div>
                  <MediaHouse
                    name="mediaHouses"
                    onChange={handleMediaSelectChange}
                    mediaHouseValue={eventEditData.mediaHouses}
                    validationRules={
                      eventEditData?.category === "MEDIA_ENGAGEMENT"
                        ? {
                            required:
                              "Media Houses are required for Media Engagement",
                          }
                        : {}
                    }
                  />
                </Col>
              </Row>
            )}
            {(eventEditData?.category === "IN_PERSON_SESSION_MENTORSHIP" ||
              eventEditData?.category === "IN_PERSON_SESSION_COUNSELLING" ||
              eventEditData?.category === "MEDIA_ENGAGEMENT" ||
              eventEditData?.category === "IN_PERSON_SESSION" ||
              eventEditData?.category === "") && (
              <>
                <RegionDistrict
                  name="location.districtId"
                  onChange={handleFormSelectChanged}
                  districtValue={eventEditData.location?.districtId}
                  regionValue={eventEditData.location?.regionId}
                />

                <Row className="m-0 p-0">
                  <Col className="" lg={6}>
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <div className="d-flex align-items-center">
                        <Form.Label className="event-label">City</Form.Label>
                      </div>
                      <Form.Control
                        className="event-details-form"
                        type="text"
                        placeholder="City"
                        name="location.city"
                        value={eventEditData.location.city}
                        onChange={handleFormChanged}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="" lg={6}>
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <div className="d-flex align-items-center">
                        <Form.Label className="event-label">Venue</Form.Label>
                      </div>
                      <InputGroup>
                        <InputGroup.Text>
                          <GrLocation />
                        </InputGroup.Text>
                        <Form.Control
                          className="event-details-form-with-icons"
                          type="text"
                          placeholder="Venue"
                          name="location.nameOfVenue"
                          value={eventEditData.location.nameOfVenue}
                          onChange={handleFormChanged}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}

            <Row className="m-0 p-0">
              <Col className="m-0 " lg={6}>
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <div className="d-flex align-items-center">
                    <Form.Label className="event-label">Start Time</Form.Label>
                  </div>
                  <Form.Control
                    className="event-details-form"
                    type="time"
                    name="stime"
                    value={eventEditData.stime}
                    placeholder="Start Time"
                    onChange={handleFormChanged}
                    {...register("stime", {
                      required: "Event start time is required",
                    })}
                  />
                  {errors.stime && (
                    <Form.Text className="text-danger">
                      {String(errors.stime.message)}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col className="m-0 " lg={6}>
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <div className="d-flex align-items-center">
                    <Form.Label className="event-label">End Time</Form.Label>
                  </div>
                  <Form.Control
                    className="event-details-form"
                    type="time"
                    name="etime"
                    placeholder="End Time"
                    value={eventEditData.etime}
                    onChange={handleFormChanged}
                    {...register("etime", {
                      required: "Event end time is required",
                    })}
                  />
                  {errors.etime && (
                    <Form.Text className="text-danger">
                      {String(errors.etime.message)}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>

            {(eventEditData?.category === "VIRTUAL_SESSION" ||
              eventEditData?.category === "MEDIA_ENGAGEMENT" ||
              eventEditData?.category === "") && (
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <div className="d-flex align-items-center">
                  <Form.Label className="event-label">Meeting Link</Form.Label>
                </div>
                <InputGroup>
                  <InputGroup.Text>
                    <FaLink />
                  </InputGroup.Text>
                  <Form.Control
                    className="event-details-form-with-icons"
                    type="text"
                    placeholder="Meeting link"
                    name="link"
                    value={eventEditData.link}
                    onChange={handleFormChanged}
                    {...register("link", {
                      required:
                        eventEditData?.category === "VIRTUAL_SESSION"
                          ? "Event link is required"
                          : false,
                    })}
                  />
                </InputGroup>
                {errors.link && (
                  <Form.Text className="text-danger">
                    {String(errors.link.message)}
                  </Form.Text>
                )}
              </Form.Group>
            )}
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
              <div className="d-flex align-items-center">
                <Form.Label className="event-label">Description</Form.Label>
              </div>
              <Form.Control
                as="textarea"
                rows={3}
                type="text"
                placeholder="Enter description"
                name="description"
                value={eventEditData.description}
                maxLength={255}
                onChange={handleFormChanged}
                style={{ fontSize: "14px" }}
              />
            </Form.Group>
          </Row>

          <Form>
            <div className="my-3 d-flex  align-items-center gap-4">
              <Form.Check
                type="checkbox"
                label="Public"
                name="public"
                checked={eventEditData.public}
                value={eventEditData.public ? "true" : "false"}
                onChange={handleCheckboxChange}
              />
              <Form.Check
                type="checkbox"
                label="Active"
                name="active"
                checked={eventEditData.active}
                value={eventEditData.active ? "true" : "false"}
                onChange={handleCheckboxChange}
              />
              <Col lg={3} className="d-flex gap-2 align-items-center">
                <p style={{ fontSize: "14px" }} className="m-0 p-0">
                  Status
                </p>
                <Form.Select
                  className="event-details-form"
                  aria-label="Default select example"
                  name="status"
                  value={eventEditData.status}
                  onChange={handleFormSelectChanged}
                >
                  <option value="UPCOMING">Upcoming</option>
                  <option value="STARTED">Started</option>
                  <option value="ENDED">Ended</option>
                </Form.Select>
              </Col>
            </div>
          </Form>

          <Row>
            <Col>
              <div className="d-flex align-items-center">
                <h5 className="py-3 m-0 event-label">Facilitators</h5>
                <hr className="hr  w-100"></hr>
              </div>
              <div>
                <Facilitators
                  name="facilitators"
                  onChange={handleFacilitatorSelectChange}
                  facilitatorsValue={eventEditData.facilitators}
                />
              </div>
            </Col>
            <Col>
              <div className="d-flex align-items-center">
                <h5 className="py-3 m-0 event-label">Collaborators</h5>
                <hr className="hr  w-100"></hr>
              </div>
              <div>
                <Collaborators
                  name="collaborators"
                  onChange={handleCollaboratorsSelectChange}
                  collaboratorsValue={eventEditData.collaborators}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="mb-2">
              <div className="d-flex align-items-center">
                <h5 className="py-3 m-0 event-label">Intervention</h5>
                <hr className="hr w-100"></hr>
              </div>
              <div>
                <Interventions
                  name="intervention"
                  onChange={handleFormSelectChanged}
                  interventionValue={eventEditData.intervention}
                />
              </div>
            </Col>
            <Col className="mb-2">
              <div className="d-flex align-items-center">
                <h5 className="py-3 m-0 event-label">Host</h5>
                <hr className="hr w-100"></hr>
              </div>
              <div>
                <Host
                  name="host"
                  onChange={handleFormSelectChanged}
                  hostValue={eventEditData.host}
                />
              </div>
            </Col>
          </Row>
        </Col>

        <Col
          className="px-lg-4 d-flex flex-column justify-content-between"
          lg={4}
        >
          <div className="mx-auto">
            <DateCalender
              date={date}
              onDateChange={handleDateChange}
              startDayValue={eventEditData.startDay}
              endDayValue={eventEditData.endDay}
            />
            <FileUpload
              name="image"
              onChange={handleImageChanged}
              imageValue={eventEditData.image}
            />
          </div>
          <div>
            <Row className="px-4 pb-4">
              <Col
                className="m-0 p-0  d-flex justify-content-end align-items-center"
                lg={6}
              >
                <Button
                  variant="dark"
                  className="w-100 event-details-button fs-6 rounded-5"
                  onClick={handleFormSubmit}
                >
                  Update event <IoIosArrowRoundForward />
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default EditEventDetails;
