import { ChangeEvent, useState } from "react";
import toast from "react-hot-toast";
import { Button, Form, Modal } from "react-bootstrap";
import { createChannelType } from "../../../../../../../models/request/mentoring-request";
import {
  useCreateChannelRequestMutation,
} from "../../../../../../../services/mentoring-service";

const CreateGroupChannel = ({ groupID }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState<createChannelType>({
    groupId: groupID,
    platform: "",
    url: "",
  });

  const handleFormChanged = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [createChannel] = useCreateChannelRequestMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    let formattedUrl = formData.url;
    if (!/^https?:\/\//i.test(formattedUrl)) {
      formattedUrl = `https://${formattedUrl}`;
    }

    try {
      const response = await createChannel({ ...formData, url: formattedUrl });
      const { code, message } = response["data"];
      if (code === 0) {
        setFormData({
          groupId: groupID,
          platform: "",
          url: "",
        });
        toast.success(message);
        handleClose();
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };

  return (
    <>
      <Button
        style={{ fontSize: "14px" }}
        variant="dark"
        className="rounded-5"
        onClick={handleShow}
      >
        Create Channel +
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">Create Channel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> PlatForm</Form.Label>
              <Form.Select
                className="text-muted"
                size="lg"
                name="platform"
                value={formData.platform}
                onChange={handleFormChanged}
              >
                <option value="" disabled>
                  Select platform
                </option>
                <option value="WHATSAPP">Whatsapp</option>
                <option value="GOOGLE_MEET">Google Meet</option>
                <option value="ZOOM">Zoom</option>
                <option value="TELEGRAM">Telegram</option>
                <option value="CLUBHOUSE">Clubhouse</option>
                <option value="TWITTER_SPACE ">Twitter Space</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Platform Link</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                placeholder="url"
                name="url"
                onChange={handleFormChanged}
                value={formData.url}
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button
            className="rounded-5"
            variant="primary"
            onClick={handleFormSubmit}
            disabled={!formData.platform || !formData.url}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateGroupChannel;
