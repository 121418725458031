import React from "react";
import CreateMentorshipEventsHeader from "./components/CreateMentorshipEventsHeader";
import CreateMentorshipEventsBody from "./components/CreateMentorshipEventsBody";
import { useParams } from "react-router-dom";

const CreateMentorshipEvents = () => {
    const {groupId} = useParams()
  return (
    <div>
      <CreateMentorshipEventsHeader />
      <CreateMentorshipEventsBody groupId={groupId}/>
    </div>
  );
};

export default CreateMentorshipEvents;
