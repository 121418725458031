import { useContext, useState } from "react";
import { Accordion, Image, Nav } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { IoSettingsOutline } from "react-icons/io5";
import { FaUsers } from "react-icons/fa6";
import AuthContext from "../pages/auth/utils/AuthContext";

const ProfileDropDown: React.FC = () => {
  const [firstName] = useState(sessionStorage.getItem("firstName"));
  const [lastName] = useState(sessionStorage.getItem("lastName"));
  const fullName = `${firstName} ${lastName}`;

  const [roles] = useState(sessionStorage.getItem("roles"));

  const { logout } = useContext(AuthContext);
  let navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/auth/login");
  };
  return (
    <Accordion className="bg-transparent">
      <Accordion.Item eventKey="0" className="sidebar-accordion-item">
        <Accordion.Header className="sidebar-accordion">
          <div className="d-flex w-100 rounded-5 align-items-center">
            <Image
              src={`https://ui-avatars.com/api/?name=${firstName}+${lastName}&background=random&size=40`}
              alt="Avatar"
              roundedCircle
              className=""
            />
            <div className="ms-2">
              <p className="fw-bold mb-0 small">{fullName}</p>
              <span
                className="text-muted"
                style={{ fontSize: "12px", fontWeight: "400" }}
              >
                {roles}
              </span>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <Nav.Item className="w-100 my-1">
            <Nav.Link
              as={NavLink}
              to="/settings"
              className="nav-link text-black p-2 d-flex align-items-center"
            >
              <IoSettingsOutline />
              <span className="ms-1 small px-3 d-sm-inline">Settings</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item
            onClick={handleLogout}
            className="w-100  my-1 nav-link text-black p-2 d-flex align-items-center "
          >
            <FaUsers />
            <span
              className="ms-1 small px-3 d-sm-inline"
              style={{ cursor: "pointer" }}
            >
              Logout
            </span>
          </Nav.Item>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default ProfileDropDown;
