import { useEventCheckInMutation } from "../../../../../../services/event-service";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { checkinType } from "../../../../../../models/request/create-event.request";
import toast from "react-hot-toast";
import { ForwardIcon } from "../../../../../../presentation/assets";
import { useNavigate } from "react-router-dom";

const UserCheckIn = ({ eventId }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [firstName] = useState(sessionStorage.getItem("firstName"));
  const [lastName] = useState(sessionStorage.getItem("lastName"));
  const [phoneNumber] = useState(sessionStorage.getItem("phoneNumber"));
  const [checkInData, setCheckInData] = useState<checkinType>({
    participantFirstName: firstName,
    participantLastName: lastName,
    participantPhoneNumber: phoneNumber,
    eventId: eventId,
  });

  const [checkInMutation] = useEventCheckInMutation();

  const handleCheckIn = async (e) => {
    e.preventDefault();
    try {
      const response = await checkInMutation(checkInData);
      const { code, message } = response["data"];
      if (code === "00") {
        toast.success(message);
        navigate(`/events/event-details/${eventId}`);
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error creating region:", error);
    }
  };

  return (
    <>
      <Button
        onClick={handleShow}
        style={{ fontSize: "12px" }}
        variant="dark"
        className="rounded-5 event-button"
      >
        Check-In{" "}
        <img
          className="ms-2 d-inline-flex"
          src={ForwardIcon}
          alt=""
          width="14px"
        />
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "16px" }}>Check-In</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "14px" }}>
          Are you sure you want to check-in this event?
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button
            style={{ fontSize: "14px" }}
            variant="success"
            className="rounded-5"
            onClick={handleCheckIn}
          >
            Check-In
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserCheckIn;
