import Select from "react-select";
import { OnchangeProps } from "../../../../../../models/request/create-event.request";
import { useGetEventPartnersListQuery } from "../../../../../../services/event-service";


interface CollaboratorsType {
  id: number;
  name: string;
}

const Collaborators : React.FC<OnchangeProps> = ({ name, onChange }) => {
  const { data: response, isLoading } = useGetEventPartnersListQuery({
    page: 0,
    size: 99999999,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const users: CollaboratorsType[] = (response?.data[0]?.data ?? []) as CollaboratorsType[];

  const collaboratorsOptions: { value: number; label: string }[] = users.map(
    (user) => ({
      value: user.id,
      label: user.name,
    })
  );

  return (
    <div style={{ fontSize: "14px" }}>
      <Select
        className="basic-multi-select"
        classNamePrefix="select"
        options={collaboratorsOptions}
        isMulti
        name={name}
        onChange={onChange}
      />
    </div>
  );
};

export default Collaborators;
