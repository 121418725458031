import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Image,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import { IoCloudUploadOutline } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { IoIosArrowRoundForward } from "react-icons/io";
import CompleteYourRegistrationToast from "./components/CompleteYourRegistrationToast";
import Select from "react-select";
import {
  useGetUserByIdQuery,
  useUpdateUserByIDMutation,
} from "../../../../../services/user-service";
import { editUserType } from "../../../../../models/request/create-user.request";
import PageLoader from "../../../../components/PageLoader";
import {
  useCreateFormAnswerMutation,
  useGetUserLocationQuery,
  useUpdateUserLocationMutation,
} from "../../../../../services/form-answers-service";
import { FormAnswerRequest } from "../../../../../models/response/FormAnswerDTO";
import { useFilterFormDropdownsMutation } from "../../../../../services/form-dropdown-suggestion";

const Account = () => {
  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  // const [StoredRegistrationStatus] = useState(
  //   sessionStorage.getItem("registrationStatus")
  // );
  const userId = parseInt(storedUserId);
  const { data: userResponse, isLoading } = useGetUserByIdQuery(userId);
  const userData = userResponse?.data;
  const navigate = useNavigate();

  const springboardCategory = userData?.springboardCategory;
  console.log("springboardCategory123", springboardCategory);

  const [StoredRegistrationStatus, setStoredRegistrationStatus] = useState(
    userData?.registrationStatus
  );

  useEffect(() => {
    setStoredRegistrationStatus(userData?.registrationStatus);
  }, [userData?.registrationStatus]);
  console.log("StoredRegistrationStatus", StoredRegistrationStatus);

  const [editUserData, setEditUserData] = useState<editUserType>({
    id: userId,
    firstName: userData?.firstName,
    middleName: userData?.middleName,
    lastName: userData?.lastName,
    email: userData?.email,
    phone: userData?.phone,
    whatsappNumber: userData?.whatsappNumber,
    gender: userData?.gender,
    age: userData?.age,
    highestLevelOfEducation: userData?.highestLevelOfEducation,
    springboardCategory: Array.isArray(userData?.springboardCategory)
      ? userData.springboardCategory
      : [],
    disabilityStatus: Array.isArray(userData?.disabilityStatus)
      ? userData.disabilityStatus
      : [],
  });

  console.log("editUserData", editUserData);

  const springboardCategoryOptions = [
    { value: "Employer", label: "Employer" },
    { value: "Job Seeker(Students)", label: "Job Seeker(Students)" },
    { value: "Job Seeker(Unemployed)", label: "Job Seeker(Unemployed)" },
    { value: "Job Seeker(Employee)", label: "Job Seeker(Employee)" },
    {
      value: "Support Partner(Volunteer)",
      label: "Support Partner(Volunteer)",
    },
    { value: "Support Partner(Vendor)", label: "Support Partner(Vendor)" },
    {
      value: "Support Partner(Facilitator)",
      label: "Support Partner(Facilitator)",
    },
    {
      value: "Stakeholder",
      label: "Stakeholder",
    },
    {
      value: "Association, Group Or Partner",
      label: "Association, Group Or Partner",
    },
  ];

  const disabiltyOptions = [
    { value: "None", label: "None" },
    { value: "Blind", label: "Blind" },
    { value: "Deaf", label: "Deaf" },
    { value: "Physically Challenged", label: "Physically Challenged" },
    { value: "Speech Impaired", label: "Speech Impaired" },
    { value: "Albino", label: "Albino" },
  ];

  const handleSpringboardCategoryChange = (selectedOptions) => {
    setEditUserData((prevUserData) => ({
      ...prevUserData,
      springboardCategory: selectedOptions.map((option) => option.value),
    }));
  };

  const handledDisabilityStatusChange = (selectedOptions) => {
    setEditUserData((prevUserData) => ({
      ...prevUserData,
      disabilityStatus: selectedOptions.map((option) => option.value),
    }));
  };

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const handleFormSelectChanged: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    const { name, value } = e.target;
    setEditUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const [editUser] = useUpdateUserByIDMutation();
  const [updateUserLocation] = useUpdateUserLocationMutation();
  const [createUserForm] = useCreateFormAnswerMutation<FormAnswerRequest>();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const missingFields = [];
    if (editUserData.springboardCategory.length === 0)
      missingFields.push("Springboard Category");
    if (editUserData.disabilityStatus.length === 0)
      missingFields.push("Disability Status");

    if (missingFields.length > 0) {
      const errorMessage = `${missingFields.join(", ")} must not be empty`;
      toast.error(errorMessage);
      return;
    }

    try {
      const response = await editUser(editUserData);
      const { code, message } = response["data"];
      if (code === 200) {
        toast.success(message);

        const finalResponse = {
          springboardCategory: editUserData.springboardCategory,
        };

        if (location) {
          // Map form state to location data using formQuestionId
          const locationPayload = location.map((loc) => {
            switch (loc.formQuestionId) {
              case 157: // region
                return { id: loc.id, answerProvided: formState.region };
              case 160: // district
                return { id: loc.id, answerProvided: formState.district };
              case 159: // communities
                return { id: loc.id, answerProvided: formState.communities };
              case 158: // town
                return { id: loc.id, answerProvided: formState.town };
              default:
                return loc;
            }
          });

          // Send update request
          await updateUserLocation({ answers: locationPayload });
        }

        if (isNaN(codeLocation) || codeLocation === 404) {
          const payload = {
            formId: 13,
            answeredBy: userId,
            answers: [
              { formQuestionId: 157, answerProvided: formState.region },
              { formQuestionId: 158, answerProvided: formState.town },
              { formQuestionId: 160, answerProvided: formState.district },
              { formQuestionId: 159, answerProvided: formState.communities },
            ],
          };

          await createUserForm(payload);
        }

        navigate(`/auth/signup-registration-2`, {
          state: { finalResponse, userId },
        });
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    setEditUserData((prev) => ({
      ...prev,
      id: userId,
      firstName: userData?.firstName,
      middleName: userData?.middleName,
      lastName: userData?.lastName,
      email: userData?.email,
      phone: userData?.phone,
      whatsappNumber: userData?.whatsappNumber,
      gender: userData?.gender,
      age: userData?.age,
      highestLevelOfEducation: userData?.highestLevelOfEducation,
      springboardCategory: Array.isArray(userData?.springboardCategory)
        ? userData.springboardCategory
        : [],
      disabilityStatus: Array.isArray(userData?.disabilityStatus)
        ? userData.disabilityStatus
        : [],
    }));
  }, [userData, userId]);

  const { data: response } = useGetUserLocationQuery(userId);

  const location = response?.data?.answers;
  const locationCode = response?.code;
  console.log("locationCode", locationCode);
  const codeLocation = parseInt(locationCode);
  console.log("codeLocation", codeLocation);

  useEffect(() => {
    if (location) {
      const region =
        location.find((answer) => answer.formQuestionId === 157)
          ?.answerProvided || "";
      const district =
        location.find((answer) => answer.formQuestionId === 160)
          ?.answerProvided || "";
      const communities =
        location.find((answer) => answer.formQuestionId === 159)
          ?.answerProvided || "";
      const town =
        location.find((answer) => answer.formQuestionId === 158)
          ?.answerProvided || "";

      setFormState({
        region,
        district,
        communities,
        town,
      });
    }
  }, [location]);

  const [formState, setFormState] = useState({
    region: "",
    district: "",
    communities: "",
    town: "",
  });

  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filterFormDropdown] = useFilterFormDropdownsMutation();

  useEffect(() => {
    fetchDropdownSuggestions(157, setRegions);
  }, []);

  useEffect(() => {
    if (formState.region) {
      fetchDropdownSuggestions(160, setDistricts, formState.region);
    }
  }, [formState.region]);

  useEffect(() => {
    if (formState.district) {
      fetchDropdownSuggestions(159, setCommunities, formState.district);
    }
  }, [formState.district]);

  const fetchDropdownSuggestions = async (
    questionId,
    setSuggestions,
    bindValue = null
  ) => {
    setLoading(true);
    try {
      const response = await filterFormDropdown({
        bind_value: bindValue,
        question_id: questionId,
      }).unwrap();
      setSuggestions(response.data || []);
    } catch (error) {
      console.error(
        `Failed to fetch suggestions for question ${questionId}:`,
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <Container className="bg-white">
      {StoredRegistrationStatus === "INCOMPLETE" && (
        <CompleteYourRegistrationToast />
      )}
      <Row>
        <Col className=" p-3" md={6}>
          <h5 className="m-0 p-0 settings-title">Profile Photo</h5>
          <p className="m-0 p-0 settings-text text-muted">Description</p>
        </Col>
        <Col style={{ backgroundColor: "#FAFAFA" }} md={6}>
          <div className="mx-auto p-3 d-grid">
            <Image
              src="https://placehold.co/140x140"
              alt=""
              className="img-fluid rounded-circle mx-auto "
            />
            <div className="mx-auto p-3">
              <Button
                variant="primary"
                className=" rounded-5 text-white bg-black border-0 settings-text"
              >
                Upload Photo
                <IoCloudUploadOutline className="mx-2 text-white" />
              </Button>
              <MdDelete className="text-white mx-1 bg-danger fs-2 rounded-circle p-1" />
            </div>
          </div>
        </Col>
      </Row>
      <hr className="hr w-100" />
      <Form onSubmit={handleFormSubmit}>
        <Row>
          <Col className=" p-3" md={6}>
            <h5 className="m-0 p-0 settings-title">General Information</h5>
            <p className="m-0 p-0 settings-text text-muted">Description</p>
          </Col>
          <Col className=" m-0 p-0" md={6}>
            <FloatingLabel
              controlId="floatingInput"
              label="Id"
              className="mb-2 settings-text"
            >
              <Form.Control
                type="text"
                placeholder="Id"
                name="id"
                value={userId}
                disabled
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="First Name"
              className="my-2 settings-text"
            >
              <Form.Control
                type="text"
                placeholder="First Name"
                name="firstName"
                value={editUserData.firstName}
                onChange={handleFormChanged}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="Middle Name"
              className="my-2 settings-text"
            >
              <Form.Control
                type="text"
                placeholder="Middle Name"
                value={editUserData.middleName}
                name="middleName"
                onChange={handleFormChanged}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="Last Name"
              className="my-2 settings-text"
            >
              <Form.Control
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={editUserData.lastName}
                onChange={handleFormChanged}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="E-mail"
              className="my-2 settings-text"
            >
              <Form.Control
                type="email"
                placeholder="E-mail"
                name="email"
                value={editUserData.email}
                onChange={handleFormChanged}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="Phone"
              className="my-2 settings-text"
            >
              <Form.Control
                type="text"
                placeholder="Phone"
                name="phone"
                value={editUserData.phone}
                onChange={handleFormChanged}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="Whatsapp Number"
              className="my-2 settings-text"
            >
              <Form.Control
                type="text"
                placeholder="Whatsapp Number"
                name="whatsappNumber"
                value={editUserData.whatsappNumber}
                onChange={handleFormChanged}
              />
            </FloatingLabel>
            <FloatingLabel
              className="mb-2 settings-text"
              controlId="floatingSelect"
              label="Sex"
            >
              <Form.Select
                aria-label="Floating label select example"
                value={editUserData.gender}
                name="gender"
                onChange={handleFormSelectChanged}
              >
                <option selected disabled>
                  Sex
                </option>
                <option value="M">Male</option>
                <option value="F">Female</option>
              </Form.Select>
            </FloatingLabel>
            <FloatingLabel
              className="mb-2 settings-text"
              controlId="floatingSelect"
              label="Age"
            >
              <Form.Select
                aria-label="Floating label select example"
                value={editUserData.age}
                name="age"
                onChange={handleFormSelectChanged}
              >
                <option selected disabled>
                  Age
                </option>
                <option value="AGE_BELOW_15">Below 15yrs</option>
                <option value="AGE_15_17">15 – 17 yrs</option>
                <option value="AGE_18_24">18 – 24 yrs</option>
                <option value="AGE_25_30">25 – 30 yrs </option>
                <option value="AGE_31_35">31 – 35 years </option>
                <option value="AGE_36_PLUS">36+ years </option>
              </Form.Select>
            </FloatingLabel>
            <FloatingLabel
              className="mb-2 settings-text"
              controlId="floatingSelect"
              label="highestLevelOfEducation"
            >
              <Form.Select
                aria-label="Floating label select example"
                value={editUserData.highestLevelOfEducation}
                name="highestLevelOfEducation"
                onChange={handleFormSelectChanged}
              >
                <option value="" disabled>
                  Highest Level of Eduction
                </option>
                <option value="No Formal Education">No Formal Education</option>
                <option value="Primary">Primary</option>
                <option value="Junior High School (JHS)">
                  Junior High School (JHS)
                </option>
                <option value="Senior High School (SHS)">
                  Senior High School (SHS)
                </option>
                <option value="TVET (Secondary)">TVET (Secondary)</option>
                <option value="TVET (Tertiary)">TVET (Tertiary)</option>
                <option value="HND/Other Diplomas">HND/Other Diplomas</option>
                <option value="First Degree / Bachelors">
                  First Degree / Bachelors
                </option>
                <option value="Master’s Degree">Master’s Degree</option>
                <option value="Doctorate Degree">Doctorate Degree</option>
              </Form.Select>
            </FloatingLabel>
            <Form.Group>
              <Select
                className="basic-multi-select mb-2"
                classNamePrefix="select"
                options={springboardCategoryOptions}
                isMulti
                name="springboardCategory"
                placeholder="Select Springboard Category"
                value={
                  editUserData.springboardCategory
                    ? springboardCategoryOptions.filter((option) =>
                        editUserData.springboardCategory.includes(option.value)
                      )
                    : []
                }
                onChange={handleSpringboardCategoryChange}
                isDisabled={StoredRegistrationStatus === "COMPLETE"}
              />
            </Form.Group>
            <Form.Group>
              <Select
                className="basic-multi-select mb-2"
                classNamePrefix="select"
                options={disabiltyOptions}
                isMulti
                name="disabilityStatus"
                placeholder="Select Disability Status"
                value={
                  editUserData.disabilityStatus
                    ? disabiltyOptions.filter((option) =>
                        editUserData.disabilityStatus.includes(option.value)
                      )
                    : []
                }
                onChange={handledDisabilityStatusChange}
                isDisabled={StoredRegistrationStatus === "COMPLETE"}
              />
            </Form.Group>
            <FloatingLabel controlId="floatingSelect" label="Region">
              <Form.Select
                name="region"
                value={formState.region}
                onChange={handleLocationChange}
              >
                <option value="">Select Region</option>
                {regions.map((region) => (
                  <option key={region.id} value={region.value}>
                    {region.value}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
            <FloatingLabel controlId="floatingSelect" label="District">
              <Form.Select
                name="district"
                value={formState.district}
                onChange={handleLocationChange}
              >
                <option value="">Select District</option>
                {districts.map((district) => (
                  <option key={district.id} value={district.value}>
                    {district.value}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
            <FloatingLabel controlId="floatingSelect" label="Communities">
              <Form.Select
                name="communities"
                value={formState.communities}
                onChange={handleLocationChange}
              >
                <option value="">Select Community</option>
                {communities.map((community) => (
                  <option key={community.id} value={community.value}>
                    {community.value}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
            <FloatingLabel controlId="floatingInput" label="Town">
              <Form.Control
                type="text"
                name="town"
                value={formState.town}
                onChange={handleLocationChange}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row className="px-4 pb-4 justify-content-end">
          <Button
            variant="dark"
            type="submit"
            className="w-50  mt-4 event-details-button fs-6 rounded-5"
          >
            Update user <IoIosArrowRoundForward />
          </Button>
        </Row>
      </Form>
    </Container>
  );
};

export default Account;
