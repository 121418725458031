import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Col, Container, Row } from "react-bootstrap";
import BulkUpload from "./BulkUpload";
import ExportData from "./ExportData";
import FilterModal from "./FilterModal";
import SampleBulkUploadFile from "./SampleBulkUploadFile";
import { useGetUsersListFilterQuery } from "../../../../../../services/user-service";
import CreateUserModal from "./CreateUserModal";
import PageLoader from "../../../../../components/PageLoader";
import SharedPagination from "../../../../../components/Pagination";
import UsersTableComponent from "../data/UsersTable";

const UsersDataTable = ({ searchQuery }) => {
  const roles = sessionStorage.getItem("roles");
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [pageSize] = useState(100);
  const [filterOptions, setFilterOptions] = useState({
    age: "",
    gender: "",
    registration_channel: "",
    start_date: "",
    end_date: "",
  });

  const handleFilter = (options) => {
    setFilterOptions(options);
    setCurrentPageIndex(0);
  };

  useEffect(() => {
    setCurrentPageIndex(0); // Reset page index when searchQuery changes
  }, [searchQuery]);

  //search, filter and export Data
  const { data: users, isLoading } = useGetUsersListFilterQuery({
    page_index: currentPageIndex,
    size: searchQuery ? 99999999 : pageSize,
    filterParams: (() => {
      const filters = [];
      if (filterOptions.age) filters.push(`age=${filterOptions.age}`);
      if (filterOptions.gender) filters.push(`gender=${filterOptions.gender}`);
      if (filterOptions.registration_channel)
        filters.push(
          `registration_channel=${filterOptions.registration_channel}`
        );
      if (filterOptions.start_date)
        filters.push(`start_date=${filterOptions.start_date}`);
      if (filterOptions.end_date)
        filters.push(`end_date=${filterOptions.end_date}`);
      return filters.join("&");
    })(),
  });

  const filteredData =
    users?.data.filter((user) =>
      Object.values(user).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      )
    ) || [];

  //Filter and Export data
  const [exportPageSize] = useState(99999999);

  const { data: response } = useGetUsersListFilterQuery({
    page_index: currentPageIndex,
    size: exportPageSize,
    filterParams: (() => {
      const filters = [];
      if (filterOptions.age) filters.push(`age=${filterOptions.age}`);
      if (filterOptions.gender) filters.push(`gender=${filterOptions.gender}`);
      if (filterOptions.registration_channel)
        filters.push(
          `registration_channel=${filterOptions.registration_channel}`
        );
      if (filterOptions.start_date)
        filters.push(`start_date=${filterOptions.start_date}`);
      if (filterOptions.end_date)
        filters.push(`end_date=${filterOptions.end_date}`);
      return filters.join("&");
    })(),
  });

  const exportfilteredData =
    response?.data.filter((user) =>
      Object.values(user).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      )
    ) || [];

  const handleResetPage = () => {
    setCurrentPageIndex(0);
  };

  const shouldDisplayData = roles !== "Coordinator" || searchQuery;

  const totalPages = users?.totalPages || 0;
  const totalElements = users?.totalElements || 0;

  const UsersTable = UsersTableComponent();
  return (
    <Container>
      <Row>
        <Col>
          <CreateUserModal />
        </Col>
        <Col className="d-flex flex-wrap justify-content-end align-items-center mb-4">
          <SampleBulkUploadFile />
          <FilterModal onFilter={handleFilter} resetPage={handleResetPage} />
          <BulkUpload />
          <ExportData
            exportfilteredData={exportfilteredData}
            resetPage={handleResetPage}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="w-100 h-auto">
            {isLoading ? (
              <PageLoader />
            ) : (
              <>
                {shouldDisplayData ? (
                  <>
                    <DataGrid
                      rows={(filteredData || []) as any[]}
                      columns={UsersTable}
                      checkboxSelection
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      slots={{ toolbar: GridToolbar }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: false,
                        },
                      }}
                      sx={{
                        "& .header--bg": {
                          backgroundColor: "#EBF1FC",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          backgroundColor: "#EBF1FC",
                          fontSize: "12px",
                        },
                        "& .Mui-checked .MuiSvgIcon-root": {
                          fill: "black",
                          background: "white",
                        },
                        "& .MuiSvgIcon-root": {
                          width: "18px",
                          height: "18px",
                          borderRadius: "4px",
                          color: "#EBEBEB",
                        },
                        "&.MuiDataGrid-root": {
                          border: "none",
                          fontSize: "14px",
                          fontWeight: "400",
                          fontFamily: "Inter",
                          backgroundColor: "white",
                        },

                        "& .MuiDataGrid-footerContainer": {
                          display: "none",
                        },
                      }}
                    />
                    <SharedPagination
                      currentPage={currentPageIndex + 1}
                      onPageChange={(newPage) =>
                        setCurrentPageIndex(newPage - 1)
                      }
                      totalPages={totalPages}
                      totalElements={totalElements}
                      pageSize={pageSize}
                    />
                  </>
                ) : (
                  <p>No data available.</p>
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default UsersDataTable;
