
import Select from "react-select";
import { OnchangeProps } from "../../../../../../models/request/create-event.request";
import { useGetEventMediaHouseListQuery } from "../../../../../../services/event-service";

interface MediaType {
  id: number;
  name: string;
  category: string;
}

interface MediaProps extends OnchangeProps {
  mediaHouseValue: number[];
}

const MediaHouse: React.FC<MediaProps & { validationRules: any }> = ({ name, onChange, mediaHouseValue, validationRules }) => {
  const { data: response, isLoading } = useGetEventMediaHouseListQuery({
    page: 0,
    size: 99999999,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const mediaHouse: MediaType[] = (response?.data[0]?.data ?? []) as MediaType[];

  const MediaOptions: { value: number; label: string }[] = mediaHouse.map(
    (media) => ({
      value: media.id,
      label: `${media.name} - ${media.category}`,
    })
  );

  return (
    <div style={{ fontSize: "14px" }}>
      <Select
        className="basic-multi-select"
        classNamePrefix="select"
        options={MediaOptions}
        isMulti
        name={name}
        onChange={onChange}
        value={mediaHouseValue ? MediaOptions.filter(option => mediaHouseValue.includes(option.value)) : []}
        {...validationRules}
      />
    </div>
  );
};

export default MediaHouse;
