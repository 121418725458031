import { useGetInterventionQuery } from "../../../../../../services/event-service";
import { OnchangeProps } from "../../../../../../models/request/create-event.request";
import { Form } from "react-bootstrap";

interface InterventionType {
  id: number;
  title: string;
}

interface interventionProps extends OnchangeProps {
  interventionValue: number;
}

const Interventions: React.FC<interventionProps> = ({ name, interventionValue, onChange }) => {
  const { data: response, isLoading } = useGetInterventionQuery({
    page: 0,
    size: 99999999,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const intervene: InterventionType[] = (response?.data[0]?.data ??
    []) as InterventionType[];

  const InterventionOptions: { value: number; label: string }[] = intervene.map(
    (inter) => ({
      value: inter.id,
      label: inter.title,
    })
  );

  return (
    <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
      <Form.Select
        className="event-details-form"
        aria-label="Default select example"
        name={name}
        onChange={onChange}
        value={interventionValue}
      >
        <option className="text-muted" value="" selected disabled>
          Select intervention
        </option>
        {InterventionOptions.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};

export default Interventions;
