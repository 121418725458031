import { ReactNode } from "react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

interface CarouselProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode[];
}

const SwiperComponent: React.FC<CarouselProps> = ({ children }) => {
  return (
    <Swiper
      spaceBetween={20}
      loop={false}
      slidesPerView={"auto"}
      centeredSlides={false}
      navigation={true}
      modules={[Navigation, Pagination, Autoplay]}
      // breakpoints={{
      //   0: {
      //     slidesPerView: 1,
      //   },
      //   375: {
      //     slidesPerView: 1,
      //   },
      //   575: {
      //     slidesPerView: 1,
      //   },
      //   767: {
      //     slidesPerView: 2,
      //   },
      //   1024: {
      //     slidesPerView: 3,
      //   },
      // }}
      className="mySwiper"
    >
      {children.map((child, index) => (
        <SwiperSlide key={index}>{child}</SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SwiperComponent;
