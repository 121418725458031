import { Col, Row, Image } from "react-bootstrap";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { IoIosCloseCircle } from "react-icons/io";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { OnchangeProps } from "../../../../../../models/request/create-event.request";
import { useDeleteFileMutation, useUploadFileMutation } from "../../../../../../services/file-upload-service";

interface ImagerProps extends OnchangeProps {
  imageValue: string;
}

const FileUpload: React.FC<ImagerProps> = ({ name, onChange, imageValue }) => {
  const [fileData, setFileData] = useState<File | null>(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string | null>(null);
  const [uploading] = useUploadFileMutation();
  const [deleting] = useDeleteFileMutation();

  const isSupportedFileType = (file: File) => {
    const supportedTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/gif",
    ];
    return supportedTypes.includes(file.type);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];

    if (selectedFile && isSupportedFileType(selectedFile)) {
      setFileData(selectedFile);
    } else {
      toast.error("Please select a valid file (PNG, JPEG, JPG, or GIF)");
    }
  };

  useEffect(() => {
    if (fileData) {
      handleFormSubmit();
    }
  }, [fileData]);

  const handleFormSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }
    try {
      const formData = new FormData();
      formData.append("file", fileData);

      const uploadResponse = await uploading(formData);

      const { code, message, data } = uploadResponse["data"];

      if (code === 200) {
        toast.success(message);
        if (typeof data === "string") {
          setUploadedImageUrl(data);
          onChange({
            target: {
              name,
              value: `${process.env.REACT_APP_IMAGE_BASE_URL}/${data}`,
            },
          });
        } else {
          console.error("Invalid URL received:", data);
        }
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error:", error);
    }
  };

  const handleDeleteImage = async () => {
    console.log("imageValue", imageValue);
    if (imageValue) {
      try {
        const filename = imageValue.split("/").pop();
        console.log("Filename:", filename);

        const deleteResponse = await deleting(filename);
        const { code, message } = deleteResponse["data"];
        if (code === 200) {
          setUploadedImageUrl(null);
          onChange({
            target: {
              name,
              value: "",
            },
          });
          toast.success(message);
        } else {
          toast.error(message);
        }
      } catch (error) {
        toast.error(error.message);
        console.error("Error:", error);
      }
    }
  };

  return (
    <div>
      <div className="bg-white rounded-4 mt-4">
        <Row>
          <Col className="p-4">
            <h5 className="text-center" style={{ fontSize: "12px" }}>
              Drag and drop event flyer here
            </h5>
            <h5 className="text-center text-muted" style={{ fontSize: "12px" }}>
              or
            </h5>
            <form onSubmit={handleFormSubmit}>
              <label
                htmlFor="fileInput"
                className="rounded-5 d-table mx-auto bg-dark text-white p-2"
                style={{ fontSize: "12px" }}
              >
                Choose file <AiOutlineCloudUpload className="my-auto ms-2" />
                <input
                  id="fileInput"
                  type="file"
                  style={{ display: "none" }}
                  name="file"
                  onChange={handleFileChange}
                />
              </label>
              {/* <button type="submit">Upload</button> */}
            </form>
          </Col>
        </Row>
        {imageValue && (
          <Row className="position-relative">
            <IoIosCloseCircle
              className="position-absolute top-0 end-0 cursor-pointer"
              style={{ fontSize: "20px", color: "red" }}
              onClick={handleDeleteImage}
            />
            <Image className="w-50 h-50 mx-auto mb-3" src={imageValue} alt="" />
          </Row>
        )}
      </div>
      <p style={{ fontSize: "12px" }}>PNG, JPEG, GIF</p>
    </div>
  );
};

export default FileUpload;
