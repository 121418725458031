import { ChangeEvent, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { GoArrowRight } from "react-icons/go";
import { VscAdd } from "react-icons/vsc";
import { slotRequest } from "../../../../../../models/request/counselling-request";
import { useAvailabilitySlotMutation } from "../../../../../../services/counselling-service";
import toast from "react-hot-toast";

const AddCounsellorAvailability = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const userId = parseInt(storedUserId);

  const [formData, setFormData] = useState<slotRequest>({
    mentorId: userId,
    times: [
      {
        time: {
          value: 0,
          unit: "",
        },
        days: [""],
        daysDate: [""],
        startTime: "",
        endTime: "",
      },
    ],
  });

  // Function to handle changes in the form fields
  const handleFormChanged = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
  
    if (name.includes("StartTime") || name.includes("EndTime")) {
      const day = name.replace(/(StartTime|EndTime)/, "");
      const timeType = name.includes("StartTime") ? "startTime" : "endTime";
  
      setFormData((prevFormData) => ({
        ...prevFormData,
        times: [{
          ...prevFormData.times[0],
          [timeType]: day.toLowerCase() === prevFormData.times[0].days[0].toLowerCase() ? value : prevFormData.times[0][timeType],
        }],
      }));
    } else if (name === "unit" || name === "value") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        times: [{
          ...prevFormData.times[0],
          time: {
            ...prevFormData.times[0].time,
            [name]: name === "value" ? parseInt(value, 10) : value,
          },
        }],
      }));
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }
  };
  

  const [availability] = useAvailabilitySlotMutation();

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await availability(formData);
      const { code, message } = response["data"];
      if (code === 0) {
        // Resetting form data after successful submission
        setFormData({
          mentorId: userId,
          times: [
            {
              time: {
                value: 0,
                unit: "",
              },
              days: [""],
              daysDate: [""],
              startTime: "",
              endTime: "",
            },
          ],
        });
        toast.success(message);
        handleClose();
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };

  return (
    <>
      <Button
        className="py-2 mx-1 rounded-5"
        variant="light"
        style={{ fontSize: "12px", background: "rgba(25, 22, 29, 0.06)" }}
        onClick={handleShow}
      >
        Add Counsellor Availability +
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Availability Slot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Row>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Select
                    className="py-2"
                    name="unit"
                    aria-label="Default select example"
                    onChange={handleFormChanged}
                    defaultValue=""
                  >
                    <option value="" disabled selected>
                      Select Unit
                    </option>
                    <option value="DAILY">Daily</option>
                    <option value="WEEKLY">Weekly</option>
                    <option value="BI_WEEKLY">Bi-Weekly</option>
                    <option value="MONTHLY">Monthly</option>
                    <option value="QUARTERLY">Quarterly</option>
                    <option value="BI_ANNUALLY">Bi-Annually</option>
                    <option value="YEARLY">Yearly</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    aria-label="value"
                    type="number"
                    className="py-2"
                    placeholder="Enter Value (number of days/weeks/months/years available)"
                    name="value"
                    onChange={handleFormChanged}
                  />
                </Form.Group>
              </Col>
            </Row>

            {["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"].map((day) => (
              <div
                key={day}
                className="d-flex my-2 align-items-center flex-row flex-nowrap"
              >
                <p className="m-0 pe-2" style={{ fontSize: "13px" }}>
                  {day}:{" "}
                </p>
                <Form.Control
                  className="py-2"
                  aria-label={`${day} start time`}
                  type="time"
                  name={`${day.toLowerCase()}StartTime`}
                  onChange={handleFormChanged}
                />
                <p className="m-0 px-2">-</p>
                <Form.Control
                  className="py-2"
                  aria-label={`${day} end time`}
                  type="time"
                  name={`${day.toLowerCase()}EndTime`}
                  onChange={handleFormChanged}
                />
                <Button
                  style={{ fontSize: "14px" }}
                  className="rounded-circle ms-2"
                  variant="light"
                >
                  <VscAdd />
                </Button>
              </div>
            ))}
            <Modal.Footer>
              <Button
                className="rounded-5"
                variant="light"
                onClick={handleClose}
                style={{ fontSize: "14px" }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                style={{ fontSize: "14px" }}
                className="rounded-5"
                variant="dark"
              >
                Submit <GoArrowRight />
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddCounsellorAvailability;
