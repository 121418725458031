import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BaseResponse } from "../models/response/base-response";
import { DropdownSuggestionResponse } from "../models/response/dropdown-suggestion-filter-response";
import { AppConstants } from "../core/mocks/app-constants";

export const fileUploadService = createApi({
  reducerPath: "fileUploadService",
  baseQuery: fetchBaseQuery({
    baseUrl: `${AppConstants.formBaseUrl}`,
  }),
  tagTypes: ["Upload"],
  endpoints: (build) => ({
    uploadFile: build.mutation<BaseResponse<DropdownSuggestionResponse[]>,FormData>({
      query: (formData: FormData) => ({
        url: "file/upload",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Upload"],
    }),
    deleteFile: build.mutation<BaseResponse<DropdownSuggestionResponse[]>, string>({
      query: (file_name) => ({
        url: `file/${file_name}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Upload"],
    }),
  }),
});

export const { useUploadFileMutation, useDeleteFileMutation } =
  fileUploadService;
