import { GridColDef } from "@mui/x-data-grid";
import EditPartnerModal from "../components/EditInterventionModal";
import DeletePartnerModal from "../components/DeleteInterventionModal";

const InterventionsTable: GridColDef[] = [

  {
    field: "title",
    headerName: "Title",
    flex: 1,
    headerClassName: "header--bg",
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    headerClassName: "header--bg",
  },
  {
    field: "createdOn",
    headerName: "Created On",
    headerClassName: "header--bg",
    flex: 1,
    valueFormatter: (params: any) => new Date(params?.value).toLocaleDateString(),
  },
  {
    field: "updatedOn",
    headerName: "Updated On",
    flex: 1,
    headerClassName: "header--bg",
    valueFormatter: (params: any) => new Date(params?.value).toLocaleDateString(),
  },
  {
    field: "actions",
    headerName: "Actions",
    headerClassName: "header--bg",
    flex: 1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      console.log("params", params);
      return (
        <div>
          <EditPartnerModal intervention={params?.row} />
          <DeletePartnerModal interventionId={params?.row?.id} />
        </div>
      );
    },
  },
];

export default InterventionsTable;
