import React from "react";
import AppRouter from "./utils/AppRouter";
import { Toaster } from "react-hot-toast";
import { ScrollToTop } from "./utils/ScrollToTop";

function Startup() {
  return (
    <>
      <Toaster />
      <ScrollToTop />
      <AppRouter />
    </>
  );
}

export default Startup;
